import InSiteAlertDialog from '../../../components/InSiteAlertDialouge/InSiteAlertDialouge';
import { useEffect, useState } from 'react';
import services from '../../../services';
import { TextField, Typography, Box } from '@mui/material';
import './UserList.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomers,
  getCustomers,
  fetchCustomersByPagination,
  getCustomersTotal,
} from '../../../store/ducks/inSiteAdminState';
import SearchIcon from '@mui/icons-material/Search';
import { TextBlock } from '../../../components/text-block';
import { getUserInfo } from '../../../store/ducks/inSiteAppState';
import { getUserOrg } from '../../../utils';
import { LightTooltip } from '../../../components';
import editIcon from '../../../assets/edit.svg';
import archiveIcon from '../../../assets/archive.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '../../../components/button';
import InSiteSnackBar from '../../../components/InSIteSnackBar/InSiteSnackBar';

export default function UserList(props: any) {
  const dispatch = useDispatch();
  const [confirmationDialouge, setConfirmationDialouge] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [budgetDailougeOpen, setBudgetDailougeOpen] = useState(false);
  const [users, setUsers] = useState<any>(null);
  const [confirmationDialougeContent, setConfirmationDialougeContent] = useState('');
  const [internalRemoval, setInternalRemoval] = useState(false);
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);

  const getAllUsersInfo = async () => {
    try {
      const data = {
        org_id: 'org_' + userOrg?.access_to,
      };
      const response = await services.getAllUserForOrgAdmin(data);
      // const response = {
      //   accesses_org: [
      //     {
      //       _id: '66e88838aa9ea05592883caf',
      //       email: 'alipu@rankworks.com',
      //       name: {
      //         first_name: 'Ashrak',
      //         last_name: 'Lipu',
      //       },
      //       org_id: '66e8885daa9ea05592883cb0',
      //       permissions: [
      //         {
      //           access_to: 'org_66e8885daa9ea05592883cb0',
      //           resources: [
      //             'VIEW_CUSTOMER_ACCOUNT_USER',
      //             'VIEW_CUSTOMER_ACCOUNT',
      //             'EDIT_CUSTOMER_ACCOUNT',
      //             'VIEW_BILLING',
      //             'EDIT_BILLING',
      //             'INTEGRATION',
      //             'VIEW_MANAGER_ACCOUNT_USER',
      //             'EDIT_MANAGER_ACCOUNT_USER',
      //           ],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726515293.962591',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66e888d4aa9ea05592883cb7',
      //       email: 'qgao@rankworks.com',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: '66e8885daa9ea05592883cb0',
      //       permissions: [
      //         {
      //           access_to: 'org_66e8885daa9ea05592883cb0',
      //           resources: [
      //             'VIEW_CUSTOMER_ACCOUNT_USER',
      //             'EDIT_CUSTOMER_ACCOUNT_USER',
      //             'VIEW_CUSTOMER_ACCOUNT',
      //             'EDIT_CUSTOMER_ACCOUNT',
      //             'VIEW_BILLING',
      //             'EDIT_BILLING',
      //             'INTEGRATION',
      //             'VIEW_MANAGER_ACCOUNT_USER',
      //           ],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726589184.756406',
      //         },
      //       ],
      //     },
      //   ],
      //   accesses_acc: [
      //     {
      //       _id: '66e888d4aa9ea05592883cb7',
      //       email: 'qgao@rankworks.com',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: '66e8885daa9ea05592883cb0',
      //       permissions: [
      //         {
      //           access_to: 'acc_66e8885daa9ea05592883cb2',
      //           resources: ['SEO'],
      //           access_expires: 'Never',
      //           status: 'PENDING',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726515412.913265',
      //         },
      //         {
      //           access_to: 'acc_66e8885daa9ea05592883cb2',
      //           resources: ['ADS'],
      //           access_expires: 'Never',
      //           status: 'PENDING',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726517242.074182',
      //         },
      //         {
      //           access_to: 'acc_66e88db596c63b1b3b505cf9',
      //           resources: ['GMB'],
      //           access_expires: 'Never',
      //           status: 'PENDING',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726517242.075278',
      //         },
      //         {
      //           access_to: 'acc_66e88db596c63b1b3b505cf9',
      //           resources: ['SMP'],
      //           access_expires: 'Never',
      //           status: 'PENDING',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726518000.595406',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66e88873aa9ea05592883cb5',
      //       email: 'powep40340@asaud.com',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: null,
      //       permissions: [
      //         {
      //           access_to: 'acc_66e8885daa9ea05592883cb2',
      //           resources: ['SMP', 'GMB'],
      //           access_expires: 'Never',
      //           status: 'PENDING',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726515315.722282',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66e88d6296c63b1b3b505cf5',
      //       email: 'cn71v@starmail.net',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: null,
      //       permissions: [
      //         {
      //           access_to: 'acc_66e8885daa9ea05592883cb2',
      //           resources: ['SMP'],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726516579.201097',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66e9aebe7ebbffd2abe4b775',
      //       email: 'chris@rankworks.com',
      //       name: {
      //         first_name: 'Chris',
      //         last_name: 'Keirl',
      //       },
      //       org_id: null,
      //       permissions: [
      //         {
      //           access_to: 'acc_66ec8099666dccd5992bf1fc',
      //           resources: ['ADS', 'SMP', 'GMB', 'SEO'],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726777726.328407',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66e88d9a96c63b1b3b505cf7',
      //       email: 'rahavin858@cetnob.com',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: null,
      //       permissions: [
      //         {
      //           access_to: 'acc_66e8885daa9ea05592883cb2',
      //           resources: ['ADS', 'SMP', 'GMB', 'SEO'],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726516635.129705',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66e9a4dd2efbb268389c4f76',
      //       email: 'in-site@rankworks.com',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: '66e9e3f1bbbe09a6115412f9',
      //       permissions: [
      //         {
      //           access_to: 'acc_66e8885daa9ea05592883cb2',
      //           resources: ['SMP', 'GMB'],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726588125.774861',
      //         },
      //         {
      //           access_to: 'acc_66e88db596c63b1b3b505cf9',
      //           resources: ['GMB'],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726588125.775754',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66eb13ded83f19ed9dfb4c2a',
      //       email: 'media@rankworks.com',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: '66eb19133b966b16db2bfd18',
      //       permissions: [
      //         {
      //           access_to: 'acc_66e88db596c63b1b3b505cf9',
      //           resources: ['ADS', 'SMP', 'GMB', 'SEO'],
      //           access_expires: 'Never',
      //           status: 'ACTIVATED',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726682079.142858',
      //         },
      //       ],
      //     },
      //     {
      //       _id: '66ec8a6b666dccd5992bf200',
      //       email: 'bipelefe@logsmarter.net',
      //       name: {
      //         first_name: '',
      //         last_name: '',
      //       },
      //       org_id: null,
      //       permissions: [
      //         {
      //           access_to: 'acc_66ec8099666dccd5992bf1fc',
      //           resources: ['ADS', 'SMP', 'GMB', 'SEO'],
      //           access_expires: 'Never',
      //           status: 'PENDING',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipggppppu@rankworks.com',
      //           date_invited: '1726777964.13134',
      //         },
      //         {
      //           access_to: 'acc_66ec8099666dccd5992bf1fc',
      //           resources: ['ADS', 'SMP', 'GMB', 'SEO'],
      //           access_expires: 'Never',
      //           status: 'PENDING',
      //           display_name: '',
      //           last_signed_in: '',
      //           event_logs: null,
      //           invited_by: 'alipu@rankworks.com',
      //           date_invited: '1726777964.13134',
      //         },
      //       ],
      //     },
      //   ],
      // };
      setUsers(response);
    } catch (error) {
      console.log(error);
      Promise.reject(error);
    }
  };

  useEffect(() => {
    if (userOrg && userOrg.access_to) getAllUsersInfo();
    // eslint-disable-next-line
  }, [userOrg]);

  const adminEmails: any = [];
  users &&
    users.accesses_org.forEach((element: any) => {
      adminEmails.push(element.email);
    });

  const filteredStaff: any = [];
  users &&
    users.accesses_acc.forEach((element: any) => {
      const result = element.permissions.some((permission: any) => adminEmails.includes(permission.invited_by));
      if (result === true) filteredStaff.push(element);
    });

  const handleRevoke = (event: any, item: any, indirectRemoval: boolean) => {
    setBudgetDailougeOpen(true);
    setCurrentUser(item);
    setInternalRemoval(indirectRemoval);
  };

  const submitRevoke = async () => {
    try {
      const data = {
        org_id: 'org_' + userOrg?.access_to,
        user_id: currentUser._id,
        indirect_removal: internalRemoval,
      };
      await services.removePermission(data);
      setConfirmationDialouge(true);
      setConfirmationDialougeContent('Permission revoked from ' + currentUser.email);
      setBudgetDailougeOpen(false);
    } catch (error) {
      console.log(error);
      setConfirmationDialouge(true);
      setConfirmationDialougeContent('An error occured!');
      setBudgetDailougeOpen(false);
    }
  };

  return (
    <>
      <InSiteSnackBar
        alertOpen={confirmationDialouge}
        alertMessage={confirmationDialougeContent}
        setAlertOpen={setConfirmationDialouge}
      />
      <Dialog
        open={budgetDailougeOpen}
        onClose={() => setBudgetDailougeOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={true}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontFamily: 'Mulish', fontSize: '16px', fontWeight: 'bold', paddingTop: '20px' }}
        >
          {'Are you sure to revoke permissions from ' + currentUser?.email + ' ?'}
        </DialogTitle>

        <DialogActions>
          <div style={{ display: 'flex', gap: '10px', paddingBottom: '20px', paddingRight: '20px' }}>
            <Button onClick={() => setBudgetDailougeOpen(false)}>Cancel</Button>
            <Button primary onClick={submitRevoke} disabled={false}>
              Revoke
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <table className="customeTable">
        <tbody>
          <tr className="admin__table_header_wrapper" key={'thead'}>
            {/* <td style={{ paddingLeft: '10px' }}>First Name</td>
            <td>Last Name</td> */}
            <td></td>
            <td style={{ paddingLeft: '10px' }}>Email</td>
            <td>User Status</td>
            <td>Role</td>
            <td>Invited By</td>
            <td>
              <div className="td__action_wrapper">
                <div className="tr__action">Actions</div>
              </div>
            </td>
          </tr>
          <br></br>
          {users &&
            users.accesses_org.map((user: any, index: number) => (
              <tr className="admin__table_tbody_tr" key={user.email}>
                <td>{index + 1}</td>
                <td>{user?.email || ''}</td>
                <td style={{ color: user?.permissions[0].status === 'PENDING' ? 'orange' : '#64F28C' }}>
                  {user?.permissions[0].status || ''}
                </td>
                <td>ADMIN</td>
                <td>{user.permissions[0].invited_by}</td>
                <td>
                  <div className="td__action_wrapper">
                    <div className="td__action">
                      {/* <LightTooltip title="Edit" placement="top">
                        <img
                          src={editIcon}
                          alt="Edit"
                          className="imgq"
                          onClick={() => {
                            props.handleEditCustomer(user.email);
                          }}
                        />
                      </LightTooltip> */}
                      {user?.email !== userInfo.email && user?.email !== user?.permissions[0].invited_by && (
                        <LightTooltip title="Archive" placement="top">
                          <img
                            src={archiveIcon}
                            alt="Archive"
                            className={user.is_delete === '1' ? 'imgd' : 'imgq'}
                            onClick={(e: any) => handleRevoke(e, user, true)}
                          />
                        </LightTooltip>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          {filteredStaff &&
            filteredStaff.map((user: any, index: number) => (
              <tr className="admin__table_tbody_tr" key={user.email}>
                <td>{index + 1 + users.accesses_org.length}</td>
                <td>{user?.email || ''}</td>
                <td style={{ color: user?.permissions[0].status === 'PENDING' ? 'orange' : '#64F28C' }}>
                  {user?.permissions[0].status || ''}
                </td>
                <td>STAFF</td>
                <td>{user.permissions[0].invited_by}</td>
                <td>
                  <div className="td__action_wrapper">
                    <div className="td__action">
                      {/* <LightTooltip title="Edit" placement="top">
                        <img
                          src={editIcon}
                          alt="Edit"
                          className="imgq"
                          onClick={() => {
                            props.handleEditCustomer(user.email);
                          }}
                        />
                      </LightTooltip> */}
                      {user?.email !== userInfo.email && (
                        <LightTooltip title="Archive" placement="top">
                          <img
                            src={archiveIcon}
                            alt="Archive"
                            className={user.is_delete === '1' ? 'imgd' : 'imgq'}
                            onClick={(e: any) => handleRevoke(e, user, false)}
                          />
                        </LightTooltip>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {!users && <span> 0 Result found</span>}
    </>
  );
}
