import _ from 'lodash';
import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import services from '../../services';
import { Event as EventType } from '../../components/calendar/types/event';

export interface InSiteAppState {
  isUserAuthenticated: boolean;
  isUserVerifiedNow: boolean;
  userRole: string;
  userInfo: any;
  appRedirectsFrom: string;
  products: any;
  notifications: any;
  popup: any;
  accountType: string;
  selectedAccount: any;
}

export const SET_IS_AUTHENTICATED_USER_TYPE = 'SET_IS_AUTHENTICATED_USER_TYPE';
export const SET_IS_VERIFIED_USER_TYPE = 'SET_IS_VERIFIED_USER_TYPE';
export const SET_USER_ROLE_TYPE = 'SET_USER_ROLE_TYPE';
export const SET_USER_INFO_TYPE = 'SET_USER_INFO_TYPE';
export const SET_APP_REDIRECTS_FROM_TYPE = 'SET_APP_REDIRECTS_FROM_TYPE';
export const SET_PRODUCTS_TYPE = 'SET_PRODUCTS_TYPE';
export const SET_DISPATCHED_GREETING_MESSAGE_TYPE = 'SET_PRODUCTS_TYPE';
export const SET_CONNECTIONS_TYPE = 'SET_CONNECTIONS_TYPE';
export const SET_EVENT_LIST = 'SET_EVENT_LIST';
export const UPDATE_CONNECTION_TYPE = 'UPDATE_CONNECTION_TYPE';
export const SET_USER_PLAN = 'SET_USER_PLAN';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const RESET_USER_NOTIFICATIONS = 'RESET_USER_NOTIFICATIONS';
export const SET_POPUP = 'SET_POPUP';
export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';
export const SET_SELECTED_ACCOUNTED_TYPE = 'SET_SELECTED_ACCOUNTED_TYPE';

// Actions

/** interface for SET_IS_AUTHENTICATED_USER_TYPE action */
export interface SetIsUserAuthenticatedActionInterface extends AnyAction {
  isUserAuthenticated: boolean;
  type: typeof SET_IS_AUTHENTICATED_USER_TYPE;
}

/** interface for SET_IS_VERIFIED_USER_TYPE action */
export interface SetIsUserVerifiedNowActionInterface extends AnyAction {
  isUserVerifiedNow: boolean;
  type: typeof SET_IS_VERIFIED_USER_TYPE;
}

/** interface for SET_DISPATCHED_GREETING_MESSAGE_TYPE action */
export interface SetIsDispatchedGreetingMessageActionInterface extends AnyAction {
  isDispatchedGreetingMessage: boolean;
  type: typeof SET_DISPATCHED_GREETING_MESSAGE_TYPE;
}

/** interface for SET_USER_ROLE_TYPE action */
export interface SetUserRoleActionInterface extends AnyAction {
  userRole: string;
  type: typeof SET_USER_ROLE_TYPE;
}
export interface SetUserInfoActionInterface extends AnyAction {
  userInfo: any;
  type: typeof SET_USER_INFO_TYPE;
}

/** interface for SET_APP_REDIRECTS_FROM_TYPE action */
export interface SetAppRedirectFromActionInterface extends AnyAction {
  appRedirectsFrom: string;
  type: typeof SET_APP_REDIRECTS_FROM_TYPE;
}

export interface SetProductsActionInterface extends AnyAction {
  products: any;
  type: typeof SET_PRODUCTS_TYPE;
}
export interface SetEventListActionInterface extends AnyAction {
  eventList: EventType[];
  type: typeof SET_EVENT_LIST;
}
export interface UpdateRankworkConnectionInterface extends AnyAction {
  connection_type: string;
  connection_value: boolean;
  type: typeof UPDATE_CONNECTION_TYPE;
}
export interface UserPlanInterface extends AnyAction {
  plan: any;
  type: typeof SET_USER_PLAN;
}

/** sets the token info status to redux store
 * @param {boolean} isUserAuthenticated - the isUserAuthenticated status
 * @returns {SetIsUserAuthenticatedActionInterface} - an action to set the is user authenticated to redux
 */
export const setIsUserAuthenticatedAction = (isUserAuthenticated: boolean): SetIsUserAuthenticatedActionInterface => ({
  isUserAuthenticated,
  type: SET_IS_AUTHENTICATED_USER_TYPE,
});

/** sets the token info status to redux store
 * @param {boolean} isUserVerifiedNow - the isUserVerifiedNow status
 * @returns {SetIsUserVerifiedNowActionInterface} - an action to set isUserVerifiedNow info to redux store
 */
export const setIsUserVerifiedNowAction = (isUserVerifiedNow: boolean): SetIsUserVerifiedNowActionInterface => ({
  isUserVerifiedNow,
  type: SET_IS_VERIFIED_USER_TYPE,
});

/** sets the token info status to redux store
 * @param {boolean} isDispatchedGreetingMessage - the isDispatchedGreetingMessage status
 * @returns {SetIsDispatchedGreetingMessageActionInterface} - an action to set isDispatchedGreetingMessage info to redux store
 */
export const setIsDispatchedGreetingMessageAction = (
  isDispatchedGreetingMessage: boolean
): SetIsDispatchedGreetingMessageActionInterface => ({
  isDispatchedGreetingMessage,
  type: SET_DISPATCHED_GREETING_MESSAGE_TYPE,
});

/** sets the token info status to redux store
 * @param {string} userRole - the userRole status
 * @returns {SetUserRoleActionInterface} - an action to set user Role info to redux store
 */
export const setUserRoleAction = (userRole: string): SetUserRoleActionInterface => ({
  userRole,
  type: SET_USER_ROLE_TYPE,
});

export const setUserInfoAction = (userInfo: any): SetUserInfoActionInterface => ({
  userInfo,
  type: SET_USER_INFO_TYPE,
});

export const setPopUpAction = (obj: any): any => ({
  obj,
  type: SET_POPUP,
});

export const setAccountTypeAction = (accountType: string): any => ({
  accountType,
  type: SET_ACCOUNT_TYPE,
});

export const setSelectedAccountAction = (selectedAccount: any): any => ({
  selectedAccount,
  type: SET_SELECTED_ACCOUNTED_TYPE,
});

/** sets the token info status to redux store
 * @param {boolean} appRedirectsFrom - the appRedirectsFrom status
 * @returns {SetIsUserVerifiedNowActionInterface} - an action to set ongoing rooms info to redux store
 */
export const setAppRedirectFromAction = (appRedirectsFrom: string): SetAppRedirectFromActionInterface => ({
  appRedirectsFrom,
  type: SET_APP_REDIRECTS_FROM_TYPE,
});

export const setProductsAction = (products: any): SetProductsActionInterface => ({
  products,
  type: SET_PRODUCTS_TYPE,
});

export const setUserPlanAction = (plan: any): UserPlanInterface => ({
  plan,
  type: SET_USER_PLAN,
});
export const setUserNotificationsAction = (data: any): any => ({
  data,
  type: SET_USER_NOTIFICATIONS,
});
export const resetUserNotificationAction = (): any => ({
  type: RESET_USER_NOTIFICATIONS,
});

export const updateRankworkConnectionAction = (
  connection_type: string,
  connection_value: boolean
): UpdateRankworkConnectionInterface => ({
  connection_type,
  connection_value,
  type: UPDATE_CONNECTION_TYPE,
});

export const setEventListAction = (eventList: EventType[]): SetEventListActionInterface => ({
  eventList,
  type: SET_EVENT_LIST,
});

/** Create type for token reducer actions */
export type tokenActionTypes =
  | SetIsUserAuthenticatedActionInterface
  | SetIsUserVerifiedNowActionInterface
  | SetUserRoleActionInterface
  | SetUserInfoActionInterface
  | SetIsDispatchedGreetingMessageActionInterface
  | AnyAction;

/** Create an immutable form state */
export type ImmutableInSiteAppState = SeamlessImmutable.ImmutableObject<InSiteAppState>;

/** initial form state */
export const initialState: ImmutableInSiteAppState = SeamlessImmutable({
  isUserAuthenticated: false,
  isUserVerifiedNow: false,
  appRedirectsFrom: '/',
  userRole: 'customer',
  userInfo: {},
  isDispatchedGreetingMessage: false,
  products: {
    loading: false,
    loaded: false,
    items: [],
    receivedAt: 0,
  },
  eventList: [],
  notifications: [],
  popup: null,
  accountType: '',
  selectedAccount: null,
});

// Reducers

/** the form reducer function */
export default function inSiteAppReducer(
  state: ImmutableInSiteAppState = initialState,
  action: tokenActionTypes
): ImmutableInSiteAppState {
  switch (action.type) {
    case SET_IS_AUTHENTICATED_USER_TYPE:
      return SeamlessImmutable({
        ...state,
        isUserAuthenticated: (action as any).isUserAuthenticated,
      });
    case SET_IS_VERIFIED_USER_TYPE:
      return SeamlessImmutable({
        ...state,
        isUserVerifiedNow: (action as any).isUserVerifiedNow,
      });
    case SET_DISPATCHED_GREETING_MESSAGE_TYPE:
      return SeamlessImmutable({
        ...state,
        isDispatchedGreetingMessage: (action as any).isDispatchedGreetingMessage,
      });
    case SET_USER_ROLE_TYPE:
      return SeamlessImmutable({
        ...state,
        userRole: (action as any).userRole,
      });
    case SET_USER_INFO_TYPE:
      return SeamlessImmutable({
        ...state,
        userInfo: (action as any).userInfo,
      });
    case SET_APP_REDIRECTS_FROM_TYPE:
      return SeamlessImmutable({
        ...state,
        appRedirectsFrom: (action as any).appRedirectsFrom,
      });
    case SET_PRODUCTS_TYPE:
      return SeamlessImmutable({
        ...state,
        products: (action as any).products,
      });
    case SET_USER_NOTIFICATIONS:
      return SeamlessImmutable({
        ...state,
        notifications: state.notifications.concat((action as any).data),
      });
    case RESET_USER_NOTIFICATIONS:
      return SeamlessImmutable({
        ...state,
        userInfo: {
          ...state.userInfo,
          notifications: {
            ...state.userInfo.notifications,
            bell_amount: 0,
          },
        },
      });
    case SET_EVENT_LIST:
      return SeamlessImmutable({
        ...state,
        eventList: (action as any).eventList,
      });
    case SET_POPUP:
      return SeamlessImmutable({
        ...state,
        popup: (action as any).obj,
      });
    case SET_ACCOUNT_TYPE:
      return SeamlessImmutable({
        ...state,
        accountType: (action as any).accountType,
      });
    case SET_SELECTED_ACCOUNTED_TYPE:
      let connectionObject = {
        facebook: false,
        instagram: false,
        gmb: false,
        google_ads: false,
        meta_ads: false,
      };

      let modifiedAccount = { ...(action as any).selectedAccount };

      if (
        (modifiedAccount?.connections?.hasOwnProperty('google_ads') &&
          modifiedAccount?.connections?.google_ads?.connection_id) ||
        (modifiedAccount?.account_details?.connections?.hasOwnProperty('google_ads') &&
          modifiedAccount?.account_details?.connections?.google_ads?.connection_id)
      )
        connectionObject['google_ads'] = true;

      if (
        (modifiedAccount?.connections?.hasOwnProperty('gmb') && modifiedAccount?.connections?.gmb?.connection_id) ||
        (modifiedAccount?.account_details?.connections?.hasOwnProperty('gmb') &&
          modifiedAccount?.account_details?.connections?.gmb?.connection_id)
      )
        connectionObject['gmb'] = true;

      if (
        (modifiedAccount?.connections?.hasOwnProperty('meta_ads') &&
          modifiedAccount?.connections?.meta_ads?.connection_id) ||
        (modifiedAccount?.account_details?.connections?.hasOwnProperty('meta_ads') &&
          modifiedAccount?.account_details?.connections?.meta_ads?.connection_id)
      )
        connectionObject['meta_ads'] = true;

      if (
        (modifiedAccount?.connections?.hasOwnProperty('facebook') &&
          modifiedAccount?.connections?.facebook?.connection_id) ||
        (modifiedAccount?.account_details?.connections?.hasOwnProperty('facebook') &&
          modifiedAccount?.account_details?.connections?.facebook?.connection_id)
      )
        connectionObject['facebook'] = true;
      if (
        (modifiedAccount?.connections?.hasOwnProperty('instagram') &&
          modifiedAccount?.connections?.instagram?.connection_id) ||
        (modifiedAccount?.account_details?.connections?.hasOwnProperty('instagram') &&
          modifiedAccount?.account_details?.connections?.instagram?.connection_id)
      )
        connectionObject['instagram'] = true;

      modifiedAccount['rankworks_connections'] = connectionObject;

      return SeamlessImmutable({
        ...state,
        selectedAccount: modifiedAccount,
      });
    case UPDATE_CONNECTION_TYPE:
      return SeamlessImmutable({
        ...state,
        userInfo: {
          ...state.userInfo,
          rankworks_connections: {
            ...state.userInfo.rankworks_connections,
            [(action as any).connection_type]: (action as any).connection_value,
          },
        },
      });
    case SET_USER_PLAN:
      return SeamlessImmutable({
        ...state,
        userInfo: {
          ...state.userInfo,
          app_plan: (action as any).plan,
        },
      });
    default:
      return state;
  }
}

// async thunk
export const fetchProducts = (): any => {
  return async (dispatch: any, getState: any) => {
    try {
      let response = await services.getAllProducts();
      const data = {
        loading: false,
        loaded: true,
        items: response,
        receivedAt: 0,
      };
      dispatch(setProductsAction(data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        setProductsAction({
          loading: false,
          loaded: true,
          items: [],
          receivedAt: 0,
        })
      );
      return Promise.reject(error);
    }
  };
};

export const fetchEventList = (startDate: number, endDate: number): any => {
  return async (dispatch: any, getState: any) => {
    try {
      let response = await services.getEventList(startDate, endDate);

      dispatch(setEventListAction(response?.data || []));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

// selectors

/** get the isUserAuthenticated info object from store
 * @param {Partial<Store>} state - the redux store
 * @return {boolean} the isUserAuthenticated status
 */
export function getIsUserAuthenticated(state: Partial<Store>): boolean {
  return (state as any).inSiteAppInfo.isUserAuthenticated;
}

/** get the isVerifiedUser info object from store
 * @param {Partial<Store>} state - the redux store
 * @return {boolean} the isUserVerifiedNow status
 */
export function getIsUserVerifiedNowStatus(state: Partial<Store>): boolean {
  return (state as any).inSiteAppInfo.isUserVerifiedNow;
}

/** get the userRole info object from store
 * @param {Partial<Store>} state - the redux store
 * @return {string} the userRole status
 */
export function getUserRole(state: Partial<Store>): string {
  return (state as any).inSiteAppInfo.userRole;
}

export function getUserInfo(state: Partial<Store>): any {
  return (state as any).inSiteAppInfo.userInfo;
}

export function getUserSocialConnections(state: Partial<Store>): string {
  return (
    (state as any).inSiteAppInfo?.selectedAccount?.rankworks_connections?.asMutable({
      deep: true,
    }) || {}
  );
}

export function getEventList(state: Partial<Store>): EventType[] {
  return (state as any).inSiteAppInfo.eventList.asMutable({
    deep: true,
  });
}

/** get the appRedirectsFrom info object from store
 * @param {Partial<Store>} state - the redux store
 * @return {string} the appRedirectsFrom status
 */
export function getAppRedirectsFromValue(state: Partial<Store>): string {
  return (state as any).inSiteAppInfo.appRedirectsFrom;
}

export function getProductsByPage(state: Partial<Store>, id: string): any {
  let products =
    (state as any).inSiteAppInfo.products.asMutable({
      deep: true,
    }) || [];

  let res = _.filter(products.items, (item: any) => item.ui.insite_category === id);
  return res;
}
export function getNotifications(state: Partial<Store>): any {
  let notifications =
    (state as any).inSiteAppInfo.notifications.asMutable({
      deep: true,
    }) || [];

  return notifications;
}

export function getIsDispatchedGreetingMessage(state: Partial<Store>): boolean {
  return (state as any).inSiteAppInfo.isDispatchedGreetingMessage;
}

export function getPopupObj(state: Partial<Store>): boolean {
  return (state as any).inSiteAppInfo.popup;
}

export function getAccountType(state: Partial<Store>): string {
  return (state as any).inSiteAppInfo.accountType;
}

export function getSelectedAccount(state: Partial<Store>): string {
  return (state as any).inSiteAppInfo.selectedAccount;
}
