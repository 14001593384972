import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AuthProvider from './contexts/AuthContext';
import useRefreshAuthentication from './hooks/useRefreshAuthentication/useRefreshAuthentication';
import getDB from './services/db';
import { AppRouter } from './router';

import './index.scss';

import { useSelector } from 'react-redux';
import { getIsUserAuthenticated, getUserRole } from './store/ducks/inSiteAppState';
import { ContentWrapper } from './components/content-wrapper';

function App() {
  const appAuthenticating = useRefreshAuthentication();
  const role = useSelector(getUserRole);
  const isAuthenticated = useSelector(getIsUserAuthenticated);
  window.addEventListener('beforeunload', function (event) {
    event.stopImmediatePropagation();
  });
  window.onbeforeunload = (event: any) => {
    event.preventDefault();
    sessionStorage.removeItem('selected_date');
    getDB().destroy();
  };

  useEffect(() => {
    getDB();
    // document.getElementById('hide-olark-chat')?.click();
  }, []);

  const Base = () => {
    return (
      <Router>
        <AuthProvider>
          <Suspense fallback={isAuthenticated ? <ContentWrapper>Loading...</ContentWrapper> : <span>Loading...</span>}>
            <AppRouter role={role} />
          </Suspense>
        </AuthProvider>
      </Router>
    );
  };

  return <div className="App">{!appAuthenticating && <Base />}</div>;
}

export default App;
