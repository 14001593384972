import { useEffect, useState } from 'react';
import services from '../../../services';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import useDebounceValidation from '../../../hooks/useDebounceValidation/useDebounceValidation';
import { Button } from '../../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers } from '../../../store/ducks/inSiteAdminState';
import useStyles from '../../../theme';
import { getProcessedPhoneNo, getUserOrg } from '../../../utils';
import Creatable from 'react-select/creatable';
import { categories } from '../../../constants';
import AsyncSelect from 'react-select/async';
import './AddAccount.scss';
import { TextField } from '../../../components/text-field';
import { Label } from '../../../components/label';
import { TextBlock } from '../../../components/text-block';
import { Radio } from '../../../components/radio';
import { Frame } from '../../../components/frame';
import { Section } from '../../get-help/ui/components/section';
import { getUserInfo } from '../../../store/ducks/inSiteAppState';
import ReactGoogleAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
interface OptionType {
  value: string;
  label: string;
}

export default function AddAccount(props: any) {
  const [value, setValue] = useState('e-commerce');
  const [companyName, setCompanyName] = useState<string>(props?.customerObj?.company?.name || '');
  const [phoneNo, setPhoneNo] = useState<string>(props?.customerObj?.phone?.number || '');
  const [ext, setExt] = useState<string>(props?.customerObj?.phone?.ext || '');
  const [website, setWebsite] = useState<string>(props?.customerObj?.company?.website || '');
  const [websiteType, setWebsiteType] = useState<OptionType | null>(null);
  const [industryType, setIndustryType] = useState<OptionType | null>(
    props?.customerObj?.company?.primary_category
      ? { label: props?.customerObj?.company?.primary_category, value: props?.customerObj?.company?.primary_category }
      : null
  );
  const [accountType, setAccountType] = useState<OptionType | null>({
    label: 'Add New Website',
    value: '1',
  });
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [isFormFocused, setIsFormFocused] = useState<boolean>(false);
  const [serviceAreaList, setServiceAreaList] = useState([]);
  const [serviceArea, setServiceArea] = useState(null);
  const [selectedServiceArea, setSelectedServiceArea] = useState<any | null>(null);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(props?.customerObj?.phone?.number ? true : false);

  const isWebSiteInvalid = accountType?.value === '1' ? !website : !websiteType;

  const addAccountValidation = creatingAccount || !companyName || !serviceArea || isWebSiteInvalid;
  const editAccountValidation = !isValidPhone || !phoneNo || !isFormFocused;

  let websiteList: any = [];
  userInfo?.permissions &&
    userOrg?.organization_details?.accounts.forEach((element: any) => {
      const data = {
        label: element?.websites[0].website || '',
        value: element?.websites[0].website_id || '',
      };
      websiteList.push(data);
    });

  let industryTypeList: any = [];
  categories.forEach((element: string) => {
    const item = {
      value: element,
      label: element,
    };
    industryTypeList.push(item);
  });

  const handleCreateAccount = async () => {
    try {
      setCreatingAccount(true);
      props.setAlertOpen(false);
      let data = {
        org_id: 'org_' + userOrg?.access_to || '',
        name: companyName,
        is_ecommerce: value === 'e-commerce' ? true : false,
        websites: [accountType?.value === '1' ? website : websiteType?.label],
        primary_category: industryType?.value,
        locations: [
          {
            address: selectedServiceArea,
            display_name:
              selectedServiceArea?.line1 + ' ' + selectedServiceArea?.city ||
              '' + selectedServiceArea?.state ||
              '' + selectedServiceArea?.country ||
              '',
            phone: {
              number: phoneNo,
              ext: ext,
            },
          },
        ],
      };

      console.log(data);
      await services.createOrgAccount(data);
      setCreatingAccount(false);
      props.setAlertOpen(true);
      props.setAlertMessage('Account creation successful!');
      props.cancelAction(null, 0);
      return Promise.resolve();
    } catch (e: any) {
      props.setAlertOpen(true);
      props.setAlertMessage('Error! Can not create account now!');
      setCreatingAccount(false);
      return Promise.reject();
    }
  };

  const handleServiceArea = async (obj: any) => {
    setServiceArea(obj);
    geocodeByPlaceId(obj.value.place_id)
      .then((results) => {
        const address = {
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: '',
          country_alpha2: '',
          postal_code: '',
          coordinates: {
            lat: '',
            lng: '',
          },
        };
        results[0].address_components.forEach((component: any) => {
          const types = component.types;

          if (types.includes('street_number')) {
            address.line1 = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + ' ' + address.line1;
          }

          if (types.includes('route')) {
            address.line1 += component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('subpremise')) {
            address.line2 = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('locality')) {
            address.city = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('administrative_area_level_1')) {
            address.state = component.short_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('country')) {
            address.country = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            address.country_alpha2 = component.short_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('postal_code')) {
            address.postal_code = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }
        });
        const latLng = results[0].geometry.location;
        address.coordinates.lat = latLng.lat().toString() || '';
        address.coordinates.lng = latLng.lng().toString() || '';
        setSelectedServiceArea(address);
      })
      .catch((error) => console.error(error));
  };

  // Fetch the initial value (this could be from a server or any other source)
  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const initialServiceArea = await services.getServiceArea(
          props?.customerObj?.company?.primary_service_area?.city,
          10
        );
        setServiceAreaList(initialServiceArea);
        setSelectedServiceArea({
          label: `${props?.customerObj?.company?.primary_service_area?.city}, ${props?.customerObj?.company?.primary_service_area?.state}`,
          value: props?.customerObj?.company?.primary_service_area?._id,
        });
        setServiceArea(props?.customerObj?.company?.primary_service_area);
      } catch (error) {
        console.error('Error fetching initial value:', error);
      }
    };

    if (props?.customerObj) {
      fetchInitialValue();
    }
  }, []);

  const handlePhoneText = (event: any) => {
    const chars: any = {
      '(': '',
      ')': '',
      '-': '',
    };
    let phone = event.target.value.replace(/[()-]/g, (m: any) => chars[m]);
    let regex = new RegExp('^[0-9]{10}$');
    regex.test(phone) ? setPhoneNo(getProcessedPhoneNo(phone)) : setPhoneNo(phone);
    setIsValidPhone(regex.test(phone));
  };

  const handleIndustryTypeChange = (selectedOption: OptionType | null) => {
    setIndustryType(selectedOption);
  };
  const handleWebsiteChange = (selectedOption: OptionType | null) => {
    setWebsiteType(selectedOption);
  };

  const handleAccountTypeChange = (selectedOption: OptionType | null) => {
    setAccountType(selectedOption);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <TextBlock sizeXXL bold header={props?.customerObj ? 'Edit Account' : 'Add Account'} className="hd" />
      <Section
        key={'key1'}
        style={{ marginBottom: 'var(--double-field-gap)', padding: '20px', paddingBottom: '12px', maxWidth: '635px' }}
      >
        <div className="create_acc__form">
          <form onFocus={() => setIsFormFocused(true)}>
            <div className="create_acc__form_content">
              <TextBlock header="Account Type*" className="textFieldHeader" />
              <Creatable
                createOptionPosition="first"
                allowCreateWhileLoading={true}
                className="account_type"
                classNamePrefix="account_type-select"
                isSearchable={true}
                isClearable={false}
                name="account_type"
                id="account_type"
                options={[
                  { label: 'Add New Website', value: '1' },
                  { label: 'Add Aditional Location', value: '2' },
                ]}
                value={accountType}
                onChange={handleAccountTypeChange}
                placeholder="Select Account type..."
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#00003c',
                    height: '40px',
                    '&:hover': {
                      borderColor: '#00003c',
                    },
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#8c96be',
                    fontSize: '14px',
                  }),
                }}
              />
              {accountType?.value === '1' && (
                <div style={{ display: 'flex', gap: '20px', marginTop: '10px', marginBottom: '10px' }}>
                  <Radio
                    className={''}
                    value="e-commerce"
                    name="group"
                    onChange={handleRadioChange}
                    checked={value === 'e-commerce'}
                  >
                    eCommence
                  </Radio>
                  <Radio
                    className={''}
                    value="other"
                    name="group"
                    onChange={handleRadioChange}
                    checked={value === 'other'}
                  >
                    Service Website
                  </Radio>
                </div>
              )}
              <TextBlock header="Company / Account Name*" className="textFieldHeader" />

              <TextField value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
              {/* <TextBlock header="Display Name" className="textFieldHeader" />
              <TextField value={companyDisplayName} onChange={(e) => setCompanyDisplayName(e.target.value)} /> */}
              <TextBlock header="Industry Type" className="textFieldHeader" />
              <Creatable
                createOptionPosition="first"
                allowCreateWhileLoading={true}
                className="industry_type"
                classNamePrefix="industry_type-select"
                isSearchable={true}
                isClearable={true}
                name="industry_type"
                options={industryTypeList}
                value={industryType}
                onChange={handleIndustryTypeChange}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#00003c',
                    height: '40px',
                    '&:hover': {
                      borderColor: '#00003c',
                    },
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#8c96be',
                    fontSize: '14px',
                  }),
                }}
              />
              <TextBlock header="Location*" className="textFieldHeader" />
              <ReactGoogleAutocomplete
                apiKey="AIzaSyC5a2Ldhg_3HzR8qVGC_gvOcirZ_RIMQ3c"
                selectProps={{
                  value: serviceArea,
                  onChange: handleServiceArea,
                }}
                apiOptions={{
                  language: 'en', // Specify the language code here (e.g., 'fr' for French)
                  region: 'ca',
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: 'ca', // Restrict suggestions to Canada
                  },
                }}
              />
              <TextBlock header="Website*" className="textFieldHeader" />
              {accountType?.value === '1' ? (
                <TextField onChange={(e: any) => setWebsite(e.target.value)} defaultValue={website} />
              ) : (
                <Creatable
                  createOptionPosition="first"
                  allowCreateWhileLoading={true}
                  className="website"
                  classNamePrefix="website-select"
                  isSearchable={false}
                  isClearable={false}
                  name="industry_type"
                  options={websiteList}
                  value={websiteType}
                  onChange={handleWebsiteChange}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: '#00003c',
                      height: '40px',
                      '&:hover': {
                        borderColor: '#00003c',
                      },
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      color: '#8c96be',
                      fontSize: '14px',
                    }),
                  }}
                />
              )}
              {accountType?.value === '1' && (
                <>
                  <TextBlock header="Business Phone Number" className="textFieldHeader" />

                  <div className="user_phone_wrapper">
                    <TextField
                      value={phoneNo}
                      onChange={(e) => handlePhoneText(e)}
                      iconError={!!phoneNo && !isValidPhone}
                      iconSuccess={!!phoneNo && isValidPhone}
                    />
                    <TextField placeholder="Extension" value={ext} onChange={(e) => setExt(e.target.value)} />
                  </div>
                </>
              )}
            </div>
          </form>
          <div className="admin__add-customer-button-group">
            <Button onClick={() => props.cancelAction(null, 0)}>Cancel</Button>
            <Button
              disabled={props.customerObj ? editAccountValidation : addAccountValidation}
              onClick={props.customerObj ? () => {} : handleCreateAccount}
              primary
            >
              {props.customerObj ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      </Section>
    </>
  );
}
