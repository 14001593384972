import InSiteAlertDialog from '../../../components/InSiteAlertDialouge/InSiteAlertDialouge';
import { useEffect, useState } from 'react';
import services from '../../../services';
import { Button, TextField, Typography, Box } from '@mui/material';
import sendEmailIcon from '../../../assets/send-email.svg';
import editIcon from '../../../assets/edit.svg';
import archiveIcon from '../../../assets/archive.svg';
import useStyles from '../../../theme';
import './AccountList.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomers,
  getCustomers,
  fetchCustomersByPagination,
  getCustomersTotal,
} from '../../../store/ducks/inSiteAdminState';
import SearchIcon from '@mui/icons-material/Search';
import { IOSSwitch, SortIcons, LightTooltip } from '../../../components';
import Textarea from '@mui/joy/Textarea';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getExternalOrgAccounts, getTagManagerScript, getUserOrg } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { TextBlock } from '../../../components/text-block';
import { getUserInfo, setUserInfoAction } from '../../../store/ducks/inSiteAppState';

export default function AccountList(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let accounts: any = [];
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);

  if (userInfo?.permissions) {
    userOrg.organization_details?.accounts.forEach((element: any) => {
      accounts.push(element);
    });
  }

  const externalAccounts: any = getExternalOrgAccounts(userInfo?.permissions);

  useEffect(() => {
    getAccountInfo();
    // eslint-disable-next-line
  }, []);

  const getAccountInfo = async () => {
    try {
      let response = await services.getAccountInfo();
      // FIXME: No check for response. If return error try/cath not catch it.
      dispatch(setUserInfoAction(response));
    } catch (error) {
      console.log(error);
      Promise.reject(error);
    }
  };

  return (
    <>
      <table className="customeTable">
        <tbody>
          <tr className="admin__table_header_wrapper" key={'thead'}>
            <td style={{ paddingLeft: '10px' }}></td>
            <td>
              <div className="td_wrapper"> Account Name</div>
            </td>
            <td>
              <div className="td_wrapper">Location</div>
            </td>
            <td>
              <div className="td_wrapper">Website</div>
            </td>
            <td>Date Added</td>
            <td>
              <div className="td__action_wrapper">
                <div className="tr__action">Actions</div>
              </div>
            </td>
          </tr>
          <br></br>
          {accounts.map((account: any, index: number) => (
            <tr className="admin__table_tbody_tr" key={account.name}>
              <td>{index + 1}</td>
              <td>{account.name}</td>
              <td>
                {(account?.locations && account?.locations[0].display_name) ||
                  (account?.locations && account?.locations[0].location_id)}
              </td>
              <td>{account?.websites[0]?.website || ''}</td>
              <td>{account?.date_invited || ''}</td>
              <td>
                <div className="td__action_wrapper">
                  <div className="td__action">
                    {/* <LightTooltip title="Edit" placement="top">
                      <img src={editIcon} alt="Edit" className="imgq" onClick={() => {}} />
                    </LightTooltip> */}
                    <LightTooltip title="Archive" placement="top">
                      <img
                        src={archiveIcon}
                        alt="Archive"
                        // className={user.is_delete === '1' ? 'imgd' : 'imgq'}
                        onClick={(event: any) => {}}
                      />
                    </LightTooltip>
                  </div>
                </div>
              </td>
            </tr>
          ))}
          {externalAccounts.map((account: any, index: number) => (
            <tr className="admin__table_tbody_tr" key={account.name}>
              <td>{accounts.length + index + 1}</td>
              <td>{account?.account_details.name}</td>
              <td>
                {(account?.account_details?.locations && account?.account_details?.locations[0].display_name) ||
                  (account?.account_details.locations && account?.account_details.locations[0].location_id)}
              </td>
              <td>{account?.account_details?.websites[0]?.website || ''}</td>
              <td>{account?.date_invited}</td>
              <td>
                <div className="td__action_wrapper">
                  <div className="td__action">
                    <LightTooltip title="Archive" placement="top">
                      <img
                        src={archiveIcon}
                        alt="Archive"
                        // className={user.is_delete === '1' ? 'imgd' : 'imgq'}
                        onClick={(event: any) => {}}
                      />
                    </LightTooltip>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {accounts.length == 0 && <span> 0 Result found</span>}
    </>
  );
}
