import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { Modal } from '../../components/modal';
import { PageWrapper } from '../../components/page-wrapper';
import { TextBlock } from '../../components/text-block';
import { AppRoute } from '../../router';
import services from '../../services';

const Invitation = (props: any) => {
  const { headerText } = props;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');
  const [isInvitationAccepted, setIsInvitationAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const BASE_URL = process.env.REACT_APP_ENV_WEB_DOMAIN || '';

  useEffect(() => {
    getInvitationStatus();
  }, []);

  const getInvitationStatus = async () => {
    try {
      setIsLoading(true);
      const data = {
        token: token,
      };
      await services.invitationStatus(data);
      setIsInvitationAccepted(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsInvitationAccepted(false);
      console.log(error);
      Promise.reject(error);
    }
  };

  const navigate = useNavigate();

  const handleClose = () => {
    window.location.href = BASE_URL;
  };
  
  return (
    <PageWrapper>
      <Modal sizeM noOverlay setOpen={handleClose}>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <TextBlock centred frame header="Hello and welcome to RankWorks!">
              {isInvitationAccepted
                ? "You've accepted the invite, now access your account."
                : 'The invitation has already been accepted, please sign in.'}
            </TextBlock>
            <Button primary onClick={handleClose}>
              Go to Account
            </Button>
          </>
        )}
      </Modal>
    </PageWrapper>
  );
};

export default Invitation;
