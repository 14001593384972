import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { StarRating } from '../../components/star-rating';
import { TextBlock } from '../../components/text-block';
import cls from './competitor-list.module.scss';
import { Icon } from 'leaflet';
import CustomIcon from '../../assets/icons/icn-location.svg';
import { useEffect, useState } from 'react';
import services from '../../services';

import 'leaflet/dist/leaflet.css';
import { useDispatch } from 'react-redux';
import { getCompetitorList, setDashboardCompetitotListAction } from '../../store/ducks/dashboardState';
import { classNames } from '../../utils/class-names';
import { isMobile } from '../../utils/is-mobile';
import { useSelector } from 'react-redux';
import { getSelectedAccount } from '../../store/ducks/inSiteAppState';
interface CompetitorListProps {
  userInfo: any;
}

const customIcon = new Icon({
  iconUrl: CustomIcon,
});

const CompetitorList = (props: CompetitorListProps) => {
  const { userInfo } = props;
  const dispatch = useDispatch();
  let competitorList = useSelector(getCompetitorList);
  const selectedAccount: any = useSelector(getSelectedAccount);

  const getCompetitors = async () => {
    if (!selectedAccount?.websites || selectedAccount?.account_details?.websites) return;
    const data = {
      primary_category: selectedAccount?.primary_category || selectedAccount?.account_details?.primary_category,
      primary_service_area:
        selectedAccount?.locations[0]?.primary_service_area ||
        selectedAccount?.account_details?.locations[0]?.primary_service_area,
    };

    const response = await services.getCompetitorsByIndustryType(data);

    if (response.hasOwnProperty('id')) {
      const serpId = response.id;
      const serpResponse = await services.getCompetitorsBySerpId(serpId);
      //setCompetitorList(serpResponse.competitors);
      dispatch(setDashboardCompetitotListAction(serpResponse.competitors));
    } else {
      //setCompetitorList(response.competitors);
      dispatch(setDashboardCompetitotListAction(response.competitors));
    }
  };

  useEffect(() => {
    if (selectedAccount && competitorList.length === 0) getCompetitors();
  }, [selectedAccount]);

  let defaultLat = 40.7128;
  let defaultLong = -73.935242;

  if (selectedAccount?.locations && selectedAccount?.locations[0]?.coordinates?.lat)
    defaultLat = selectedAccount?.locations[0]?.coordinates?.lat;
  if (selectedAccount?.account_details && selectedAccount?.account_details?.locations[0]?.coordinates?.lat)
    defaultLat = selectedAccount?.account_details?.locations[0]?.coordinates?.lat;

  if (selectedAccount?.locations && selectedAccount?.locations[0]?.coordinates?.lng)
    defaultLong = selectedAccount?.locations[0]?.coordinates?.lng;
  if (selectedAccount?.account_details && selectedAccount?.account_details?.locations[0]?.coordinates?.lng)
    defaultLong = selectedAccount?.account_details?.locations[0]?.coordinates?.lng;

  const defaultCoordinates: any = [defaultLat, defaultLong];

  const checkIfCurrentCompany = (companyName: string) => {
    if (userInfo?.company?.name?.toLowerCase() === companyName.toLowerCase()) {
      return true;
    }
    return false;
  };

  const renderCompetitorInner = (item: any) => {
    return (
      <>
        <div className={classNames(cls.name, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}>
          {item.title}
        </div>
        <div
          className={classNames(cls.rating, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}
        >
          {item.rating}
        </div>
        <div className={classNames(cls.star, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}>
          <StarRating value={item.rating} id="1" />
        </div>
        <div
          className={classNames(cls.review, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}
        >
          ({item.review})
        </div>
      </>
    );
  };

  const renderCompetitor = (item: any) => {
    if (!isMobile()) return renderCompetitorInner(item);

    return (
      <div className={cls.mobileWrapper} key={item.name}>
        {renderCompetitorInner(item)}
      </div>
    );
  };

  return (
    <div className={cls.competitorsWrapperRoot}>
      <TextBlock header="Here’s the top 10 competitors within your category." />
      <div className={cls.competitorsWrapper}>
        <div className={cls.competitors}>
          <div className={cls.innerWrapper}>
            <div className={cls.competitorList}>
              {competitorList.map((item: any) => renderCompetitor(item))}
              {competitorList.length === 0 && (
                <div className={cls.competitorsWrapper}>
                  <TextBlock className={cls.myRanking}>
                    We are collecting your competitor data. Please check back later.
                  </TextBlock>
                </div>
              )}
            </div>
          </div>
        </div>
        <MapContainer className={cls.map} center={defaultCoordinates} zoom={13} scrollWheelZoom={false}>
          <TileLayer url="https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/512/png8?apiKey=xcvFjvkJ3KHSA4Q3IlQVbeqgHXvK7sa3-wlFKX7-SFc" />
          <Marker position={defaultCoordinates} icon={customIcon}>
            <Popup>{userInfo?.company?.name || 'You'}</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export { CompetitorList };
