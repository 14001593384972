import { useEffect, useState } from 'react';
import services from '../../../services';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import useDebounceValidation from '../../../hooks/useDebounceValidation/useDebounceValidation';
import { Button } from '../../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers } from '../../../store/ducks/inSiteAdminState';
import useStyles from '../../../theme';
import { getExternalOrgAccounts, getProcessedPhoneNo, getUserOrg } from '../../../utils';
import Creatable from 'react-select/creatable';
import { categories } from '../../../constants';
import AsyncSelect from 'react-select/async';
import './AddUser.scss';
import { TextField } from '../../../components/text-field';
import { Label } from '../../../components/label';
import { TextBlock } from '../../../components/text-block';
import Box from '@mui/material/Box';
import { Section } from '../../get-help/ui/components/section';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getUserInfo } from '../../../store/ducks/inSiteAppState';

interface OptionType {
  value: string;
  label: string;
}

export default function AddUser(props: any) {
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userType, setUserType] = useState<OptionType>({
    label: 'Standard',
    value: 'STANDARD',
  });
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  let checkboxData: any = [];
  let adminCheckboxData: any = [
    {
      id: 'accounts',
      label: 'Accounts',
      children: [
        { id: 'view', label: 'View' },
        { id: 'edit', label: 'Edit' },
      ],
    },
    {
      id: 'users',
      label: 'Users',
      children: [
        { id: 'view', label: 'View' },
        { id: 'edit', label: 'Edit' },
      ],
    },
    {
      id: 'billing',
      label: 'Billing',
      children: [
        { id: 'view', label: 'View' },
        { id: 'edit', label: 'Edit' },
      ],
    },
  ];
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);
  const externalAccounts: any = getExternalOrgAccounts(userInfo?.permissions);

  console.log(externalAccounts);

  if (userInfo?.permissions) {
    userOrg?.organization_details?.accounts.forEach((element: any) => {
      const item = {
        id: element._id,
        label: element.name,
        children: [
          { id: 'ADS', label: 'Ads' },
          { id: 'SMP', label: 'Social' },
          { id: 'GMB', label: 'Google Business' },
          { id: 'SEO', label: 'SEO' },
          { id: 'GA4', label: 'Website Analytics' },
        ],
      };
      checkboxData.push(item);
    });
    externalAccounts.forEach((element: any) => {
      const resourses: any = element.resources;
      const chrildrenArray: any = [];
      resourses.forEach((item: any) => {
        if (item === 'SMP') chrildrenArray.push({ id: 'SMP', label: 'Social' });
        if (item === 'ADS') chrildrenArray.push({ id: 'ADS', label: 'Ads' });
        if (item === 'GMB') chrildrenArray.push({ id: 'GMB', label: 'Google Business' });
        if (item === 'SEO') chrildrenArray.push({ id: 'SEO', label: 'SEO' });
        if (item === 'GA4') chrildrenArray.push({ id: 'GA4', label: 'Website Analytics' });
      });

      const item = {
        id: element.access_to,
        label: element.account_details.name,
        children: chrildrenArray,
      };
      checkboxData.push(item);
    });
  }

  // Initialize state for all checkboxes using the data structure
  const [checkedState, setCheckedState] = useState(
    checkboxData.reduce((acc: any, parent: any) => {
      acc[parent.id] = {
        checked: false,
        children: parent.children.map((child: any) => ({ id: child.id, checked: false })),
      };
      return acc;
    }, {} as { [key: string]: { checked: boolean; children: { id: string; checked: boolean }[] } })
  );
  const [checkedAdminState, setCheckedAdminState] = useState<any>({
    accounts: { view: true, edit: false },
    users: { view: true, edit: false },
    billing: { view: true, edit: true },
  });

  const isChecked = Object.values(checkedState).some((item: any) => {
    // Check if the parent or any child has 'checked' set to true
    return item.children.some((child: any) => child.checked === true);
  });

  const isAdminChecked = Object.values(checkedAdminState).some((section: any) =>
    Object.values(section).some((value) => value === true)
  );

  const addAccountValidation = creatingAccount || !email || !userType || !isChecked;
  const addAdminAccountValidation = creatingAccount || !email || !userType || !isAdminChecked;

  const handleUserTypeChange = (selectedOption: any) => {
    setUserType(selectedOption);
  };

  // Handle change for parent checkboxes
  const handleParentChange = (parentId: string) => {
    const currentState = checkedState[parentId];
    const allChecked = !currentState.checked;
    setCheckedState({
      ...checkedState,
      [parentId]: {
        ...currentState,
        checked: allChecked,
        children: currentState.children.map((child: any) => ({ ...child, checked: allChecked })),
      },
    });
  };

  console.log(checkboxData, checkedState, userType);

  const handleCreateUser = async () => {
    try {
      setCreatingAccount(true);
      props.setAlertOpen(false);

      const accessArray = Object.keys(checkedState)
        .map((key) => {
          const item = checkedState[key];

          // Filter children with `checked: true`
          const resources = item.children.filter((child: any) => child.checked === true).map((child: any) => child.id);

          // Return the final object if there are any resources
          return resources.length > 0 ? { access_to: 'acc_' + key, resources: resources } : null;
        })
        .filter((item) => item !== null);

      let data = {
        email: email,
        // name: {
        //   first_name: firstName,
        //   last_name: lastName,
        // },
        access_level: userType?.value,
        org_id: 'org_' + userOrg?.organization_details?._id || '',
        org_name: userOrg?.organization_details?.name || '',
        // invited_by: userOrg?.invited_by || '',
        accounts: accessArray,
      };
      console.log(data);
      await services.createUserPermisson(data);
      setCreatingAccount(false);
      props.setAlertOpen(true);
      props.setAlertMessage('User creation successful!');
      props.cancelAction(null, 0);
      return Promise.resolve();
    } catch (e: any) {
      props.setAlertOpen(true);
      props.setAlertMessage('Error! Can not create user now!');
      setCreatingAccount(false);
      return Promise.reject();
    }
  };

  const handleCreateAdminUser = async () => {
    try {
      setCreatingAccount(true);
      props.setAlertOpen(false);

      const formatResourceString = (key: any) => {
        switch (key) {
          case 'accounts':
            return 'CUSTOMER_ACCOUNT';
          case 'users':
            return 'CUSTOMER_ACCOUNT_USER';
          case 'billing':
            return 'BILLING';
          default:
            return key.toUpperCase();
        }
      };

      // Generate the `OrgResourceEnum` array
      const OrgResourceEnum = Object.entries(checkedAdminState).flatMap(([key, permissions]: any) => {
        return Object.entries(permissions)
          .filter(([, value]) => value) // Include only permissions that are true
          .map(([action]) => {
            // Construct the string using the action and key
            const actionStr = action.toUpperCase();
            const resourceStr = formatResourceString(key);
            return `${actionStr}_${resourceStr}`;
          });
      });

      OrgResourceEnum.push('VIEW_MANAGER_ACCOUNT_USER');
      OrgResourceEnum.push('INTEGRATION');

      let data = {
        email: email,
        // name: {
        //   first_name: firstName,
        //   last_name: lastName,
        // },
        access_level: userType?.value,
        org_id: 'org_' + userOrg?.organization_details?._id || '',
        org_name: userOrg?.organization_details?.name || '',
        invited_by: userOrg?.invited_by || '',
        accounts: [
          {
            access_level: 'ADMIN',
            access_to: 'org_' + userOrg?.access_to,
            resources: OrgResourceEnum,
          },
        ],
      };
      console.log(data);
      await services.createUserPermisson(data);
      setCreatingAccount(false);
      props.setAlertOpen(true);
      props.setAlertMessage('User creation successful!');
      props.cancelAction(null, 0);
      return Promise.resolve();
    } catch (e: any) {
      props.setAlertOpen(true);
      props.setAlertMessage('Error! Can not create user now!');
      setCreatingAccount(false);
      return Promise.reject();
    }
  };

  // Handle change for child checkboxes
  const handleChildChange = (parentId: string, childId: string) => {
    const parentState = checkedState[parentId];
    const children = parentState.children.map((child: any) =>
      child.id === childId ? { ...child, checked: !child.checked } : child
    );
    const allChecked = children.every((child: any) => child.checked);
    setCheckedState({
      ...checkedState,
      [parentId]: {
        ...parentState,
        checked: allChecked,
        children: children,
      },
    });
  };

  const handleAdminChildChange = (parentId: any, childId: any) => {
    setCheckedAdminState((prevState: any) => {
      // Create a deep copy of the previous state
      const updatedState = { ...prevState };

      // Toggle the specific checkbox state
      updatedState[parentId][childId] = !prevState[parentId][childId];

      // Return the updated state
      return updatedState;
    });
  };

  const userResources = (userInfo?.permissions && userOrg?.resources) || [];

  return (
    <>
      <TextBlock sizeXXL bold header={props?.customerObj ? 'Edit User' : 'Add User'} className="hd" />
      <Section
        key={'key1'}
        style={{ marginBottom: 'var(--double-field-gap)', padding: '20px', paddingBottom: '12px', maxWidth: '665px' }}
      >
        <div className="create_acc__form">
          <form onFocus={() => {}}>
            <div className="create_acc__form_content">
              <TextBlock header="Email*" className="textFieldHeader" />
              <TextField
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextBlock header="Role*" className="textFieldHeader" />
              <Creatable
                createOptionPosition="first"
                allowCreateWhileLoading={true}
                className="role"
                classNamePrefix="role-select"
                isSearchable={false}
                isClearable={false}
                name="role"
                options={
                  userResources.includes('EDIT_MANAGER_ACCOUNT_USER')
                    ? [
                        { label: 'Standard', value: 'STANDARD' },
                        { label: 'Admin', value: 'ADMIN' },
                      ]
                    : [{ label: 'Standard', value: 'STANDARD' }]
                }
                value={userType}
                onChange={handleUserTypeChange}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#00003c',
                    height: '40px',
                    '&:hover': {
                      borderColor: '#00003c',
                    },
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#8c96be',
                    fontSize: '14px',
                  }),
                }}
              />

              <TextBlock header="Account" className="textFieldHeader" />
              {userType.value === 'STANDARD' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    maxHeight: '250px',
                    marginTop: '0px',
                    marginBottom: '20px',
                    border: '1px solid gray',
                    padding: '0px',
                    borderRadius: '5px',
                    fontFamily: 'Mulish',
                  }}
                >
                  {checkboxData.map((parent: any) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <FormControlLabel
                          className="flb"
                          label={parent.label}
                          control={
                            <Checkbox
                              checked={checkedState[parent.id].checked}
                              size="small"
                              indeterminate={
                                checkedState[parent.id].children.some((child: any) => child.checked) &&
                                !checkedState[parent.id].checked
                              }
                              style={{ padding: 0, margin: 0, marginRight: '5px' }}
                              onChange={() => handleParentChange(parent.id)}
                            />
                          }
                        />
                      </AccordionSummary>

                      <AccordionDetails>
                        {parent.children.map((child: any) => (
                          <FormControlLabel
                            className="flb"
                            key={child.id}
                            label={child.label}
                            control={
                              <Checkbox
                                style={{ padding: 0, margin: 0, marginRight: '5px', paddingLeft: '20px' }}
                                size="small"
                                checked={
                                  checkedState[parent.id].children.find((c: any) => c.id === child.id)?.checked || false
                                }
                                onChange={() => handleChildChange(parent.id, child.id)}
                              />
                            }
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    maxHeight: '250px',
                    marginTop: '0px',
                    marginBottom: '20px',
                    border: '1px solid gray',
                    padding: '0px',
                    borderRadius: '5px',
                    fontFamily: 'Mulish',
                  }}
                >
                  <table style={{ padding: '10px' }}>
                    <tr>
                      <th></th>
                      <th>View</th>
                      <th>Edit</th>
                    </tr>

                    {adminCheckboxData.map((parent: any) => (
                      <tr>
                        <td> {parent.label}</td>
                        {parent.children.map((child: any) => (
                          <td>
                            <Checkbox
                              style={{ padding: 0, margin: 0, marginRight: '5px', paddingLeft: '10px' }}
                              size="small"
                              checked={checkedAdminState[parent.id][child.id]}
                              disabled={
                                (parent.id === 'accounts' || parent.id === 'users') && child.id === 'view'
                                  ? true
                                  : false
                              }
                              onChange={() => handleAdminChildChange(parent.id, child.id)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </div>
          </form>
          <div className="admin__add-customer-button-group">
            <Button onClick={() => props.cancelAction(null, 0)}>Cancel</Button>
            <Button
              disabled={userType.value === 'STANDARD' ? addAccountValidation : addAdminAccountValidation}
              onClick={userType.value === 'STANDARD' ? handleCreateUser : handleCreateAdminUser}
              primary
            >
              {props.customerObj ? 'Update' : 'Send Invite'}
            </Button>
          </div>
        </div>
      </Section>
    </>
  );
}
