import { AnyAction } from 'redux';
import { selectAccountId, selectLocationId } from './gmb-state';
import * as gmbDashboardService from '../../services/gmb-dashboard-service';

interface GMBDashboardState {
  profileViews: any;
  callsMade: any;
  platformAndDevicesUsers: any;
  websiteClicks: any;
  reviews: any;
  searchesShowed: any;
  messagesSent: any;
  directionRequests: any;
  usersOverTime: any;
}

export const SET_PROFILE_VIEWS = 'SET_PROFILE_VIEWS';
export const SET_CALLS_MADE = 'SET_CALLS_MADE';
export const SET_PLATFORM_AND_DEVICES_USERS = 'SET_PLATFORM_AND_DEVICES_USERS';
export const SET_WEBSITE_CLICKS = 'SET_WEBSITE_CLICKS';
export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_SEARCHES_SHOWED = 'SET_SEARCHES_SHOWED';
export const SET_MESSAGES_SENT = 'SET_MESSAGES_SENT';
export const SET_DIRECTION_REQUESTS = 'SET_DIRECTION_REQUESTS';
export const SET_USERS_OVER_TIME = 'SET_USERS_OVER_TIME';
export const RESET_GMB_DASHBOARD_STATE = 'RESET_GMB_DASHBOARD_STATE';

const initialState: GMBDashboardState = {
  profileViews: null,
  callsMade: null,
  platformAndDevicesUsers: null,
  websiteClicks: null,
  reviews: null,
  searchesShowed: null,
  messagesSent: null,
  directionRequests: null,
  usersOverTime: null,
};

/* -------------------------------------------------------------------------- */
/*                           interfaces for actions                           */
/* -------------------------------------------------------------------------- */
export interface SetProfileViewsInterface extends AnyAction {
  type: typeof SET_PROFILE_VIEWS;
  profileViews: any;
}

export interface SetCallsMadeInterface extends AnyAction {
  type: typeof SET_CALLS_MADE;
  callsMade: any;
}

export interface SetPlatformAndDevicesUsersInterface extends AnyAction {
  type: typeof SET_PLATFORM_AND_DEVICES_USERS;
  platformAndDevicesUsers: any;
}

export interface SetWebsiteClicksInterface extends AnyAction {
  type: typeof SET_WEBSITE_CLICKS;
  websiteClicks: any;
}

export interface SetReviewsInterface extends AnyAction {
  type: typeof SET_REVIEWS;
  reviews: any;
}

export interface SetSearchesShowedInterface extends AnyAction {
  type: typeof SET_SEARCHES_SHOWED;
  searchesShowed: any;
}

export interface SetMessagesSentInterface extends AnyAction {
  type: typeof SET_MESSAGES_SENT;
  messagesSent: any;
}

export interface SetDirectionRequestsInterface extends AnyAction {
  type: typeof SET_DIRECTION_REQUESTS;
  directionRequests: any;
}

export interface SetUsersOverTimeInterface extends AnyAction {
  type: typeof SET_USERS_OVER_TIME;
  usersOverTime: any;
}

export interface ResetStateInterface extends AnyAction {
  type: typeof RESET_GMB_DASHBOARD_STATE;
}

export type ActionType =
  | SetProfileViewsInterface
  | SetCallsMadeInterface
  | SetPlatformAndDevicesUsersInterface
  | SetWebsiteClicksInterface
  | SetReviewsInterface
  | SetSearchesShowedInterface
  | SetMessagesSentInterface
  | SetDirectionRequestsInterface
  | SetUsersOverTimeInterface
  | ResetStateInterface;

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */
export const setProfileViewsAction = (profileViews: any): SetProfileViewsInterface => ({
  profileViews,
  type: SET_PROFILE_VIEWS,
});

export const setCallsMadeAction = (callsMade: any): SetCallsMadeInterface => ({
  callsMade,
  type: SET_CALLS_MADE,
});

export const setPlatformAndDevicesUsersAction = (
  platformAndDevicesUsers: any
): SetPlatformAndDevicesUsersInterface => ({
  platformAndDevicesUsers,
  type: SET_PLATFORM_AND_DEVICES_USERS,
});

export const setWebsiteClicksAction = (websiteClicks: any): SetWebsiteClicksInterface => ({
  websiteClicks,
  type: SET_WEBSITE_CLICKS,
});

export const setReviewsAction = (reviews: any): SetReviewsInterface => ({
  reviews,
  type: SET_REVIEWS,
});

export const setSearchesShowedAction = (searchesShowed: any): SetSearchesShowedInterface => ({
  searchesShowed,
  type: SET_SEARCHES_SHOWED,
});

export const setMessagesSentAction = (messagesSent: any): SetMessagesSentInterface => ({
  messagesSent,
  type: SET_MESSAGES_SENT,
});

export const setDirectionRequestsAction = (directionRequests: any): SetDirectionRequestsInterface => ({
  directionRequests,
  type: SET_DIRECTION_REQUESTS,
});

export const setUsersOverTimeAction = (usersOverTime: any): SetUsersOverTimeInterface => ({
  usersOverTime,
  type: SET_USERS_OVER_TIME,
});

export const resetGMBDashboardStateAction = (): any => ({
  type: RESET_GMB_DASHBOARD_STATE,
});

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
export const gmbDashboardReducer = (state = initialState, action: ActionType): GMBDashboardState => {
  switch (action.type) {
    case SET_PROFILE_VIEWS:
      return { ...state, profileViews: action.profileViews };
    case SET_CALLS_MADE:
      return { ...state, callsMade: action.callsMade };
    case SET_PLATFORM_AND_DEVICES_USERS:
      return { ...state, platformAndDevicesUsers: action.platformAndDevicesUsers };
    case SET_WEBSITE_CLICKS:
      return { ...state, websiteClicks: action.websiteClicks };
    case SET_REVIEWS:
      return { ...state, reviews: action.reviews };
    case SET_SEARCHES_SHOWED:
      return { ...state, searchesShowed: action.searchesShowed };
    case SET_MESSAGES_SENT:
      return { ...state, messagesSent: action.messagesSent };
    case SET_DIRECTION_REQUESTS:
      return { ...state, directionRequests: action.directionRequests };
    case SET_USERS_OVER_TIME:
      return { ...state, usersOverTime: action.usersOverTime };
    case RESET_GMB_DASHBOARD_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

/* -------------------------------------------------------------------------- */
/*                               Thunk Actions                                */
/* -------------------------------------------------------------------------- */
export const getProfileViews =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getProfileViews(locationId, dataStart, dataEnd);
      dispatch(setProfileViewsAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getCallsMade =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getCallsMade(locationId, dataStart, dataEnd);
      dispatch(setCallsMadeAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getPlatformAndDevicesUsers =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getPlatformAndDevicesUsers(locationId, dataStart, dataEnd);
      dispatch(setPlatformAndDevicesUsersAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getWebsiteClicks =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getWebsiteClicks(locationId, dataStart, dataEnd);
      dispatch(setWebsiteClicksAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getReviews =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getReviews(locationId, dataStart, dataEnd);
      dispatch(setReviewsAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getSearchesShowed =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getSearchesShowed(locationId, dataStart, dataEnd);
      dispatch(setSearchesShowedAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getMessagesSent =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getMessagesSent(locationId, dataStart, dataEnd);
      dispatch(setMessagesSentAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getDirectionRequests =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getDirectionRequests(locationId, dataStart, dataEnd);
      dispatch(setDirectionRequestsAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getUsersOverTime =
  (dataStart: string, dataEnd: string): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await gmbDashboardService.getUsersOverTime(locationId, dataStart, dataEnd);
      dispatch(setUsersOverTimeAction(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

/* -------------------------------------------------------------------------- */
/*                                  selectors                                 */
/* -------------------------------------------------------------------------- */

export const selectProfileViews = (state: any) => state.gmbDashboard.profileViews;
export const selectCallsMade = (state: any) => state.gmbDashboard.callsMade;
export const selectPlatformAndDevicesUsers = (state: any) => state.gmbDashboard.platformAndDevicesUsers;
export const selectWebsiteClicks = (state: any) => state.gmbDashboard.websiteClicks;
export const selectReviews = (state: any) => state.gmbDashboard.reviews;
export const selectSearchesShowed = (state: any) => state.gmbDashboard.searchesShowed;
export const selectMessagesSent = (state: any) => state.gmbDashboard.messagesSent;
export const selectDirectionRequests = (state: any) => state.gmbDashboard.directionRequests;
export const selectUsersOverTime = (state: any) => state.gmbDashboard.usersOverTime;
