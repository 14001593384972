import { GraphWrapper } from '../graph-wrapper/graph-wrapper';

import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import cls from '../graph.module.scss';
import { ReactComponent as IconError } from '../../../../assets/icons/icn-error.svg';
import { useState } from 'react';
import { formatSocialValue } from '../../../../utils/format-social-value';

const GraphCriticalErrors = (props: GraphProps) => {
  const { className, dataSet, ...rest } = props;

  const [isLoading, setLoading] = useState(false);

  const totalSessions =
    dataSet?.ga4_data?.reduce((acc: number, item: any) => {
      const sessions = Number(item?.sessions) || 0;
      return acc + sessions;
    }, 0) || 0;

  const totalEngagedSessions =
    dataSet?.ga4_data?.reduce((acc: number, item: any) => {
      const engagedSessions = Number(item?.engagedSessions) || 0;
      return acc + engagedSessions;
    }, 0) || 0;

  const total =
    totalSessions !== 0 ? ((100 - (totalSessions - totalEngagedSessions) / totalSessions) * 100).toFixed(2) + '%' : '-';

  return (
    // <GraphWrapper className={className} critical {...rest}>
    //   <IconError />
    //   <TextBlock bold>29 critical errors</TextBlock>
    //   <TextBlock>Lorem ipsum dolor sit amet, consectetur adipiscing.</TextBlock>
    // </GraphWrapper>
    <GraphWrapper className={className} {...rest} header="Engagement rate %" total={formatSocialValue(total)}>
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>54%</span>
      <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { GraphCriticalErrors };
