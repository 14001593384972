// ClosePage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../components/content-wrapper';
import services from '../../services';
import {
  getUserInfo,
  getUserSocialConnections,
  updateRankworkConnectionAction,
} from '../../store/ducks/inSiteAppState';
import { classNames } from '../../utils/class-names';
import { Section } from '../get-help/ui/components/section';
import cls from './g-list.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button, ListSubheader, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { getUserOrg } from '../../utils';
// import emitter from '../../utils/emitter';

const GoogleAdsAccountSelectionPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentAccount, setCurrentAccount] = useState<any | null>(null);
  const [searchText, setSearchText] = useState('');
  const [accounts, setAccounts] = useState<any | null>(null);
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);
  const orgId: string = userOrg?.access_to || '';
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectParam = queryParams.get('redirect');

  const userSocialConnections: any = useSelector(getUserSocialConnections);

  const getAllAccs = async () => {
    try {
      setIsLoading(true);

      const data = {
        manager_account_id: '',
        org_id: 'org_' + orgId,
      };
      const response = await services.getGoogleAdsAccounts(data);

      let modifiedList: any = [];
      let customerAccountIds: any = [];

      response.forEach((element: any) => {
        element.customer_accounts.forEach((item: any) => {
          if (!customerAccountIds.includes(item.customer_account_id)) {
            const mofifiedItem = {
              connection_id: element.connection_id,
              default_customer_account: item,
              org_id: 'org_' + orgId,
            };
            modifiedList.push(mofifiedItem);
            customerAccountIds.push(item.customer_account_id);
          }
        });
      });
      console.log(response);
      setAccounts(modifiedList);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const connectGAdAccount = async () => {
    try {
      await services.setGoogleAdsCustomerAccount(currentAccount);
      dispatch(updateRankworkConnectionAction('google_ads', true));
      navigate('/integration');
    } catch (err) {
      console.log(err);
    }
  };
  const formatIDNumber = (str: string): string => {
    return str.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  useEffect(() => {
    if (orgId) getAllAccs();
    return () => {
      setCurrentAccount(null);
    };
  }, [orgId]);

  useEffect(() => {
    if (currentAccount) connectGAdAccount();
  }, [currentAccount]);

  //if (userSocialConnections && userSocialConnections?.google_ads === true) navigate('/integration');

  return (
    <ContentWrapper>
      <Box
        sx={{
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%',
          maxWidth: 500,
          bgcolor: 'background.paper',
          maxHeight: '600px',
          borderRadius: '3px',
          boxShadow: '4px 4px 0 0 #cad7e5',
          paddingBottom: '25px',
          paddingRight: '45px',
          paddingLeft: '45px',
        }}
      >
        {!isLoading && accounts && accounts.length !== 0 && (
          <List
            aria-labelledby="nested-list-subheader"
            style={{
              width: '100%',
            }}
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{
                  display: 'flex',
                  fontFamily: 'Mulish',
                  color: 'var(--Primary-Primary-400)',
                  paddingTop: '25px',
                  width: '100%',
                }}
              >
                <div className={cls.search_wrapper}>
                  <TextField
                    className={cls.search_text}
                    id="user_email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search account"
                    onChange={(event) => setSearchText(event.target.value)}
                    style={{ borderRadius: '0px !important', backgroundColor: 'white' }}
                    InputProps={{
                      startAdornment: <SearchIcon style={{ marginRight: '5px', color: '#50648c', width: '18px' }} />,
                    }}
                    inputProps={{
                      style: {
                        fontSize: '12px', // Adjust font size as needed
                        fontFamily: 'Mulish, sans-serif', // Change font family as desired
                        color: 'rgba(0, 0, 0, 0.6)', // Optional: Adjust the placeholder text color
                      },
                    }}
                  />
                </div>
              </ListSubheader>
            }
          >
            <div style={{ maxHeight: '500px', overflow: 'auto', scrollbarWidth: 'thin' }}>
              {accounts &&
                accounts
                  .filter((it: any) =>
                    it.default_customer_account.descriptive_name.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((item: any, index: any) => (
                    <ListItem disablePadding key={index} onClick={(e) => setCurrentAccount(item)}>
                      <ListItemButton style={{ width: '100%' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                          <span style={{ fontSize: '15px', fontWeight: '400' }}>
                            {item.default_customer_account.descriptive_name}
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              textAlign: 'right',
                              color: 'var(--Primary-Primary-400)',
                              minWidth: 150,
                            }}
                          >
                            {formatIDNumber(item.default_customer_account.customer_account_id)}
                          </span>
                        </div>
                      </ListItemButton>
                    </ListItem>
                  ))}
            </div>
          </List>
        )}

        {!isLoading && (!accounts || accounts.length === 0) && (
          <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', height: '50px' }}
          >
            You don't have any Google Ads account. Go Back{' '}
            <a onClick={() => navigate('/integration')} style={{ marginLeft: '10px', cursor: 'pointer' }}>
              {' '}
              Click
            </a>
          </div>
        )}
        {isLoading && (
          <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', height: '50px' }}
          >
            Collecting your accounts...
          </div>
        )}
      </Box>
    </ContentWrapper>
  );
};

export default GoogleAdsAccountSelectionPage;
