import { AnyAction } from 'redux';
import {
  getGMBAccountLocations,
  getGMBAccounts,
  getGMBProfileInfo,
  getGMBReviewList,
  postGMBReview,
} from '../../services/gmb-service';
import { Review } from '../../pages/gmb-review/model/types/review';

interface GMBState {
  account: any;
  location: any;
  profileInfo: any;
  reviewList: any[];
  isLoading: boolean;
  error: any;
}

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO';
export const SET_REVIEW_LIST = 'SET_REVIEW_LIST';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const SET_LOADING = 'SET_LOADING';
export const RESET_GMB_STATE = 'RESET_GMB_STATE';

const initialState: GMBState = {
  account: null,
  location: null,
  profileInfo: null,
  reviewList: [],
  isLoading: false,
  error: null,
};

/* -------------------------------------------------------------------------- */
/*                           interfaces for actions                           */
/* -------------------------------------------------------------------------- */
export interface SetAccountInterface extends AnyAction {
  type: typeof SET_ACCOUNT;
  account: any;
}

export interface SetLocationInterface extends AnyAction {
  type: typeof SET_LOCATION;
  location: any;
}

export interface SetProfileInfoInterface extends AnyAction {
  type: typeof SET_PROFILE_INFO;
  profileInfo: any;
}

export interface SetReviewListInterface extends AnyAction {
  type: typeof SET_REVIEW_LIST;
  reviewList: any[];
}

export interface UpdateReviewInterface extends AnyAction {
  type: typeof UPDATE_REVIEW;
  review: Review;
}

export interface DeleteReviewInterface extends AnyAction {
  type: typeof DELETE_REVIEW;
  review: Review;
}

export interface SetLoadingInterface extends AnyAction {
  type: typeof SET_LOADING;
  isLoading: boolean;
}
export interface ResetStateInterface extends AnyAction {
  type: typeof RESET_GMB_STATE;
}

export type ActionType =
  | SetAccountInterface
  | SetLocationInterface
  | SetProfileInfoInterface
  | SetReviewListInterface
  | UpdateReviewInterface
  | DeleteReviewInterface
  | SetLoadingInterface
  | ResetStateInterface;

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */
export const setAccountAction = (account: any): SetAccountInterface => ({
  account,
  type: SET_ACCOUNT,
});

export const setLocationAction = (location: any): SetLocationInterface => ({
  location,
  type: SET_LOCATION,
});

export const setProfileInfoAction = (profileInfo: any): SetProfileInfoInterface => ({
  profileInfo,
  type: SET_PROFILE_INFO,
});

export const setReviewListAction = (reviewList: any): SetReviewListInterface => ({
  reviewList: reviewList,
  type: SET_REVIEW_LIST,
});

export const updateReviewAction = (review: Review): UpdateReviewInterface => ({
  review,
  type: UPDATE_REVIEW,
});

export const deleteReviewAction = (review: Review): DeleteReviewInterface => ({
  review,
  type: DELETE_REVIEW,
});

export const setLoadingAction = (isLoading: boolean): SetLoadingInterface => ({
  isLoading,
  type: SET_LOADING,
});

export const resetGMBStateAction = (): any => ({
  type: RESET_GMB_STATE,
});

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
export default function gmbReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_ACCOUNT:
      return { ...state, account: action.account };
    case SET_LOCATION:
      return { ...state, location: action.location };
    case SET_PROFILE_INFO:
      return { ...state, profileInfo: action.profileInfo };
    case SET_REVIEW_LIST:
      return { ...state, reviewList: action.reviewList };
    case UPDATE_REVIEW:
      return {
        ...state,
        reviewList: state.reviewList.map((review: Review) =>
          review.reviewId === action.review.reviewId ? action.review : review
        ),
      };
    case DELETE_REVIEW:
      return {
        ...state,
        reviewList: state.reviewList.map((review: Review) =>
          review.reviewId === action.review.reviewId ? action.review : review
        ),
      };
    case SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    case RESET_GMB_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

/* -------------------------------------------------------------------------- */
/*                               Thunk Actions                                */
/* -------------------------------------------------------------------------- */
export const fetchGMBAccount = (): any => {
  return async (dispatch: any) => {
    try {
      const response = await getGMBAccounts();
      const accountList = response.accounts;

      if (!accountList) return Promise.reject('No GMB account response');
      if (!Array.isArray(accountList)) return Promise.reject('GMB accounts response is not an array');
      if (accountList.length === 0) return Promise.reject('GMB accounts response array is empty');

      const account = accountList.find((acc) => acc.type !== 'PERSONAL' && acc.type !== 'NOT_VETTED');
      if (!account) return Promise.reject('No GMB account found');

      dispatch(setAccountAction({ ...account, accountId: account.name.replace('accounts/', '') }));

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchGMBLocation = (): any => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const accountId = selectAccountId(state);

    if (!accountId) return Promise.reject('No GMB account found');

    try {
      const response = await getGMBAccountLocations(accountId);
      const locationList = response.locations;

      if (!locationList) return Promise.reject('No GMB location response');
      if (!Array.isArray(locationList)) return Promise.reject('GMB locations response is not an array');
      if (locationList.length === 0) return Promise.reject('GMB location response array is empty');

      const location = locationList[0];

      dispatch(setLocationAction({ ...location, locationId: location.name.replace('locations/', '') }));

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
export const setUpGMBAccountAndLocation = (accountId: string, locationId: string): any => {
  return async (dispatch: any, getState: any) => {
    dispatch(setLocationAction({ locationId: locationId }));
    dispatch(setAccountAction({ accountId: accountId }));
  };
};

export const fetchGMBProfileInfo = (): any => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const locationId = selectLocationId(state);

    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await getGMBProfileInfo(locationId);

      if (!response) return Promise.reject('No GMB location profile info response');

      dispatch(setProfileInfoAction(response));

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchReviewList = (): any => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const accountId = selectAccountId(state);
    const locationId = selectLocationId(state);

    if (!accountId) return Promise.reject('No GMB account found');
    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await getGMBReviewList(accountId, locationId);

      if (!response) return Promise.reject('No GMB review list response');
      if (!response.reviews) return;

      const reviewList = response.reviews.sort(
        (a: any, b: any) => new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime()
      );

      dispatch(setReviewListAction(reviewList));

      return Promise.resolve(reviewList);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const updateReview = (reviewId: string, comment: string): any => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const accountId = selectAccountId(state);
    const locationId = selectLocationId(state);

    if (!accountId) return Promise.reject('No GMB account found');
    if (!locationId) return Promise.reject('No GMB location found');

    try {
      const response = await postGMBReview(accountId, locationId, reviewId, comment);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

/* -------------------------------------------------------------------------- */
/*                                  selectors                                 */
/* -------------------------------------------------------------------------- */

export const selectAccount = (state: any) => state.gmbInfo?.account;
export const selectAccountId = (state: any) => state.gmbInfo?.account?.accountId;
export const selectLocation = (state: any) => state.gmbInfo?.location;
export const selectLocationId = (state: any) => state.gmbInfo?.location?.locationId;
export const selectProfileInfo = (state: any) => state.gmbInfo?.profileInfo;
export const selectReviewList = (state: any) => state.gmbInfo?.reviewList;
export const selectIsLoading = (state: any) => state.gmbInfo?.isLoading;
export const selectError = (state: any) => state.gmbInfo?.error;
