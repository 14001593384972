import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { TextBlock } from '../../../../components/text-block';

import { GraphNewUsersByCountry, GraphTrafficByDevice } from '../../../../components/graph';
import { CardGA4ActiveUsers } from '../../../../components/graph/ui/card-ga4-active-users/card-ga4-active-users';
import { CardGA4NewUsers } from '../../../../components/graph/ui/card-ga4-new-users/card-ga4-new-users';
import { GraphGA4NewUsersByDate } from '../../../../components/graph/ui/graph-ga4-new-users-by-date/graph-ga4-new-users-by-date';
import { CardGA4EngagementTime } from '../../../../components/graph/ui/card-ga4-engagement-time/card-ga4-engagement-time';
import { CardGA4PageViewsPerUser } from '../../../../components/graph/ui/card-ga4-page-views-per-user/card-ga4-page-views-per-user';
import { CompetitorList } from '../../../../components/competitor-list/competitor-list';
import { CardGA4EngagementRate } from '../../../../components/graph/ui/card-ga4-engagement-rate/card-ga4-engagement-rate';
import { CardGA4GoogleRanking } from '../../../../components/graph/ui/card-ga4-google-ranking/card-ga4-google-ranking';

import cls from './default.module.scss';
import { checkIfGA4DataIsComing, setGa4DataAction } from '../../../../store/ducks/dashboardState';
import { useEffect } from 'react';

const Default = () => {
  const dispatch = useDispatch();
  const userInfo: any = useSelector(getUserInfo);
  const ga4Status: boolean = useSelector(checkIfGA4DataIsComing);

  useEffect(() => {
    dispatch(setGa4DataAction(ga4Status));
  }, [ga4Status]);

  return (
    <>
      <div className={cls.wrapper}>
        <TextBlock header="Here's your last 30 days activity summary." />
        <div className={cls.dashboard}>
          <CardGA4ActiveUsers />
          <CardGA4NewUsers />
          <GraphTrafficByDevice large />
          <CardGA4EngagementRate />
          <CardGA4EngagementTime />
          <GraphNewUsersByCountry large />
          <CardGA4GoogleRanking />
          <CardGA4PageViewsPerUser />
          <GraphGA4NewUsersByDate large />
        </div>
      </div>
      {userInfo && <CompetitorList userInfo={userInfo} />}
    </>
  );
};

export { Default };
