import { memo, useEffect, useState } from 'react';
import { Plan } from '../../plan/plan';
import services from '../../../services';
import { Link, redirect, useLocation, useNavigate } from 'react-router-dom';
import { Payment } from '../../payment';
import { AppRoute } from '../../../router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, setUserPlanAction } from '../../../store/ducks/inSiteAppState';
import { SetCheckoutInfo, setCheckoutInfoAction } from '../../../store/ducks/paymentState';
import { PlanWrapper } from './plan-wrapper';
import { Button } from '../../button';
import cls from './insite-app-plans.module.scss';

const InsiteAppPlans = memo(() => {
  const navigate = useNavigate();
  const [newSubscriber, setNewSubscriber] = useState(false);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState<any>([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentPrice, setCurrentPrice] = useState<any>(null);
  const userInfo: any = useSelector(getUserInfo);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;

  useEffect(() => {
    if (currentPath === AppRoute.BUSINESS_PAYMENT) {
      const data = {
        planInfo: currentPlan,
        priceInfo: currentPrice,
        userInfo: userInfo,
        planType: 'plans',
      };
      console.log(data);
      dispatch(setCheckoutInfoAction(data));
      setStep(2);
    } else {
      setStep(1);
    }
  }, [currentPath]);

  const compare = (a: any, b: any) => {
    const amountA = parseInt(a.prices.find((price: any) => price.recurring.interval === 'month').unit_amount);
    const amountB = parseInt(b.prices.find((price: any) => price.recurring.interval === 'month').unit_amount);

    if (amountA < amountB) {
      return -1;
    }
    if (amountA > amountB) {
      return 1;
    }
    return 0;
  };

  const getPlans = async () => {
    try {
      const response = await services.getAllStripePlan('plans');
      let modifiedList: any = [];
      let initialPriceObject: any = {};
      response?.data.forEach((element: any) => {
        const planObj = {
          id: element.id,
          Icon: null,
          title: element.name,
          prices: element.prices.data,
          description: element.description,
          features: element.marketing_features.map((feature: any) => feature.name),
          images: element.images,
          isSubscribed: element.is_activated,
          isUpgradable: false,
        };
        modifiedList.push(planObj);
        const initialPrice: any = element.prices.data.find((price: any) => price.recurring.interval === 'month');
        initialPriceObject[element.name] = initialPrice;
      });
      modifiedList.sort(compare);

      // move 1st element to last
      modifiedList.push(modifiedList.shift());

      let isFoundUpgradablePosition = false;
      let isNewSubscriber = true;

      for (let index = 0; index < modifiedList.length; index++) {
        const item = modifiedList[index];
        if (isFoundUpgradablePosition === true) modifiedList[index]['isUpgradable'] = true;
        if (item.isSubscribed === true) {
          isFoundUpgradablePosition = true;
          isNewSubscriber = false;
        }
      }
      setPlans(modifiedList);
      setCurrentPrice(initialPriceObject);
      setNewSubscriber(isNewSubscriber);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handlePlanSelect = (value: any) => {
    setCurrentPlan(value);
    navigate(AppRoute.BUSINESS_PAYMENT);
  };

  const handleSubcribeToFreePlan = async () => {
    try {
      setIsLoading(true);
      const response = await services.createFreePlanSubscription();
      dispatch(setUserPlanAction(response?.data));
      setIsLoading(false);
      navigate('/');
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className={cls.palnWrapperParent}>
        <PlanWrapper>
          {plans &&
            step === 1 &&
            currentPrice &&
            plans.map((planStructure: any) => (
              <Plan
                key={planStructure.id}
                isNewSubscriber={newSubscriber}
                isSubscribed={planStructure.isSubscribed}
                isUpgradable={planStructure.isUpgradable}
                currentPlan={'plans'}
                onPlanSelect={handlePlanSelect}
                onPriceSelect={() => {}}
                planStructure={planStructure}
                currentPrice={currentPrice}
                trial={true}
              />
            ))}
        </PlanWrapper>
        {/* {plans.length > 0 && step === 1 && newSubscriber && (
          <div>
            <Button onClick={handleSubcribeToFreePlan} disabled={isLoading}>
              Continue with Free Plan
            </Button>
          </div>
        )} */}
        {step === 2 && <Payment />}
      </div>
    </>
  );
});

export { InsiteAppPlans };
