import { useDispatch, useSelector } from 'react-redux';

import { getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { TextBlock } from '../../../../components/text-block';

import { CompetitorList } from '../../../../components/competitor-list/competitor-list';
import { CardGA4WebsiteVisits } from '../../../../components/graph/ui/card-ga4-website-visits/card-ga4-vebsite-visits';
import { CardGA4PhoneCalls } from '../../../../components/graph/ui/card-ga4-phone-calls/card-ga4-phone-calls';
import { CardGA4BounceRate } from '../../../../components/graph/ui/card-ga4-bounce-rate/card-ga4-bounce-rate';
import { GraphCriticalErrors } from '../../../../components/graph';
import { CardGA4GoogleRanking } from '../../../../components/graph/ui/card-ga4-google-ranking/card-ga4-google-ranking';
import { CardGA4NumbersOfFormCompletion } from '../../../../components/graph/ui/card-ga4-number-of-form-completion/card-ga4-numbers-of-form-completions';

import cls from './paid.module.scss';
import ga4Service from '../../../../services/GA4-service';
import { useEffect, useState } from 'react';
import { GraphGA4UsersOverTime } from '../../../../components/graph/ui/graph-ga4-users-over-time/graph-ga4-userss-over-time';
import { GraphGA4TrafficAcquisitionPerChannelGroup } from '../../../../components/graph/ui/graph-ga4-traffic-acquisition-per-channel-gpoup/graph-ga4-traffic-acquisition-per-channel-gpoup';
import { GraphGA4SessionSource } from '../../../../components/graph/ui/graph-ga4-session-source/graph-ga4-session-source';
import { setGa4DataAction } from '../../../../store/ducks/dashboardState';

const Paid = () => {
  const userInfo: any = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const [sessionSource, setSessionSource] = useState(null);
  const [phoneCalls, setPhoneCalls] = useState(null);
  const [sessionChannels, setSessionChannels] = useState(null);
  const [formCompletion, setFormCompletion] = useState(null);

  const getData = async (email: string) => {
    console.log(email);
    Promise.all([
      ga4Service.getSessionSource(email),
      ga4Service.getPhoneCalls(email),
      ga4Service.getSessionChannels(email),
      ga4Service.getFormCompletion(email),
    ]).then((data) => {
      setSessionSource(data[0]);
      setPhoneCalls(data[1]);
      setSessionChannels(data[2]);
      setFormCompletion(data[3]);
      dispatch(setGa4DataAction(true));
    });
  };

  useEffect(() => {
    if (userInfo && userInfo?.email) getData(userInfo.email);
  }, [userInfo]);

  return (
    <>
      <div className={cls.wrapper}>
        <TextBlock header="Here's your last 30 days activity summary." />
        <div className={cls.dashboard}>
          <CardGA4WebsiteVisits dataSet={sessionSource} />
          <CardGA4PhoneCalls dataSet={phoneCalls} />
          <GraphGA4TrafficAcquisitionPerChannelGroup dataSet={sessionChannels} large />
          <CardGA4BounceRate dataSet={sessionSource} />
          <GraphCriticalErrors />
          <GraphGA4SessionSource dataSet={sessionSource} large />
          <CardGA4GoogleRanking />
          <CardGA4NumbersOfFormCompletion dataSet={formCompletion} />
          <GraphGA4UsersOverTime dataSet={sessionSource} large />
        </div>
      </div>
      {userInfo && <CompetitorList userInfo={userInfo} />}
    </>
  );
};

export { Paid };
