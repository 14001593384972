import { memo, useEffect, useState } from 'react';
import { BusinessRegistrationModal } from '../../../components/business-registration-modal/business-registration-modal';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../../../components/page-wrapper';
import cls from './business-plans.module.scss';
import { InsiteAppPlans } from '../../../components/insite-app-plans';

const BusinessPlans = memo(() => {
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <div className={cls.plans}>
        <InsiteAppPlans />
      </div>
    </PageWrapper>
  );
});

export { BusinessPlans };
