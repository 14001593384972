import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../hooks/useAuthContext/useAuthContext';

import {
  allRouteList,
  publicRoteList,
  mobileRouteList,
  adminRouteList,
  orgAdminRouteList,
} from '../model/config/route-config';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import { isMobile } from '../../utils/is-mobile';

import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountType,
  getSelectedAccount,
  getUserInfo,
  setAccountTypeAction,
} from '../../store/ducks/inSiteAppState';
import { getUserOrg } from '../../utils';
import { isEmpty } from '../../utils/is-empty';
import { AppRoute } from '../model/type/app-route';
import services from '../../services';
const Private = (Component: React.ReactElement) => <PrivateRoute>{Component}</PrivateRoute>;

const AppRouter = ({ role }: { role: string }) => {
  const { isAuthenticated } = useAuthContext();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split('/');
  const url = pathSegments[1];
  const acType: string = useSelector(getAccountType);
  const selectedAccount: any = useSelector(getSelectedAccount);
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);

  let currentRouteList = publicRoteList;

  if (isAuthenticated) {
    if (role !== 'customer') {
      currentRouteList = adminRouteList;
    } else {
      if (isMobile()) {
        currentRouteList = mobileRouteList;
      } else {
        currentRouteList = allRouteList;
      }
    }
  } else {
    currentRouteList = publicRoteList;
  }

  if (acType === 'admin') {
    currentRouteList = isAuthenticated ? orgAdminRouteList : publicRoteList;
  }

  useEffect(() => {
    if (url === 'org-admin') dispatch(setAccountTypeAction('admin'));
  }, [url]);

  if (selectedAccount?.resources) {
    currentRouteList = currentRouteList.filter((item: any) => {
      if (item.hasOwnProperty('id')) {
        if (selectedAccount?.resources.includes(item.id)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
  }

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    if (!acType) return;
    if (
      location.pathname === AppRoute.VERIFICATION ||
      location.pathname === AppRoute.LOGIN ||
      location.pathname === AppRoute.BUSINESS_PAYMENT
    )
      return;
    if (userInfo?.permissions && userInfo?.permissions?.length === 0) {
      navigate('/business');
    }
    if (userOrg?.organization_details && userOrg?.organization_details?.plans?.length === 0) {
      navigate('/business/plans');
    }
  }, [userInfo, acType]);

  return (
    <Routes>
      {currentRouteList.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={isAuthenticated ? Private(route.element) : route.element}
          />
        );
      })}
    </Routes>
  );
};

export { AppRouter };
