import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import services from '../../services';
import * as _ from 'lodash';

interface DashboardInfo {
  newUsersByCountry: any;
  newUsersByDate: any;
  activeUsersByDevice: any;
  totalActiveUsers: string;
  totalNewUsers: string;
  userSessions: string;
  userSessionsByDate: any;
  engagementData: any;
  engagementRate: string;
  competitorList: any;
}

export interface InstagramAnalyticsState {
  dashboard: DashboardInfo | null;
  ga4DataStatus: boolean;
}

export const SET_DASHBOARD_NEW_USERS_DATA_BY_COUNTRY_TYPE = 'SET_DASHBOARD_NEW_USERS_DATA_BY_COUNTRY_TYPE';
export const SET_DASHBOARD_NEW_USERS_DATA_BY_DATE_TYPE = 'SET_DASHBOARD_NEW_USERS_DATA_BY_DATE_TYPE';
export const SET_DASHBOARD_ACTIVE_USERS_DATA_BY_DEVICE_TYPE = 'SET_DASHBOARD_ACTIVE_USERS_DATA_BY_DEVICE_TYPE';
export const SET_DASHBOARD_TOTAL_NEW_USERS_TYPE = 'SET_DASHBOARD_TOTAL_NEW_USERS_TYPE';
export const SET_DASHBOARD_TOTAL_ACTIVE_USERS_TYPE = 'SET_DASHBOARD_TOTAL_ACTIVE_USERS_TYPE';
export const SET_DASHBOARD_USER_SESSIONS_TYPE = 'SET_DASHBOARD_USER_SESSIONS_TYPE';
export const SET_DASHBOARD_USER_SESSIONS_BY_DATE_TYPE = 'SET_DASHBOARD_USER_SESSIONS_BY_DATE_TYPE';
export const SET_DASHBOARD_ENGAGEMENT_DATA_TYPE = 'SET_DASHBOARD_ENGAGEMENT_DATA_TYPE';
export const SET_DASHBOARD_ENGAGEMENT_RATE_TYPE = 'SET_DASHBOARD_ENGAGEMENT_RATE_TYPE';
export const SET_DASHBOARD_COMPETITOR_LIST_TYPE = 'SET_DASHBOARD_COMPETITOR_LIST_TYPE';
export const RESET_DASHBOARD_TYPE = 'RESET_DASHBOARD_TYPE';
export const SET_GA4DATA_TYPE = 'SET_GA4DATA_TYPE';

// Interfaces

export interface DashboardNewUsersDataByCountryActionInterface extends AnyAction {
  data: any;
  type: typeof SET_DASHBOARD_NEW_USERS_DATA_BY_COUNTRY_TYPE;
}
export interface ResetDashboardInterface extends AnyAction {
  type: typeof RESET_DASHBOARD_TYPE;
}
export interface DashboardEngagementDataActionInterface extends AnyAction {
  data: any;
  type: typeof SET_DASHBOARD_ENGAGEMENT_DATA_TYPE;
}
export interface DashboardNewUsersDataByDateActionInterface extends AnyAction {
  data: any;
  type: typeof SET_DASHBOARD_NEW_USERS_DATA_BY_DATE_TYPE;
}
export interface DashboardTotalNewUsersActionInterface extends AnyAction {
  newUsers: string;
  type: typeof SET_DASHBOARD_TOTAL_NEW_USERS_TYPE;
}
export interface DashboardUserSessionsActionInterface extends AnyAction {
  userSessions: string;
  type: typeof SET_DASHBOARD_USER_SESSIONS_TYPE;
}
export interface DashboardUserSessionsBYDateActionInterface extends AnyAction {
  data: any;
  type: typeof SET_DASHBOARD_USER_SESSIONS_BY_DATE_TYPE;
}
export interface DashboardTotalActiveUsersActionInterface extends AnyAction {
  activeUsers: string;
  type: typeof SET_DASHBOARD_TOTAL_ACTIVE_USERS_TYPE;
}

export interface DashboardActiveUsersDataByDeviceActionInterface extends AnyAction {
  data: any;
  type: typeof SET_DASHBOARD_ACTIVE_USERS_DATA_BY_DEVICE_TYPE;
}
export interface DashboardCompetitotListActionInterface extends AnyAction {
  data: any;
  type: typeof SET_DASHBOARD_COMPETITOR_LIST_TYPE;
}
export interface DashboardEngagementRateActionInterface extends AnyAction {
  engagementRate: string;
  type: typeof SET_DASHBOARD_ENGAGEMENT_RATE_TYPE;
}

// Actions

export const resetDashboardAction = (): ResetDashboardInterface => ({
  type: RESET_DASHBOARD_TYPE,
});

export const setGa4DataAction = (status: boolean): any => ({
  status,
  type: SET_GA4DATA_TYPE,
});

export const setDashboardNewUsersDataByCountryAction = (data: any): DashboardNewUsersDataByCountryActionInterface => ({
  data,
  type: SET_DASHBOARD_NEW_USERS_DATA_BY_COUNTRY_TYPE,
});

export const setDashboardEngagementDataAction = (data: any): DashboardEngagementDataActionInterface => ({
  data,
  type: SET_DASHBOARD_ENGAGEMENT_DATA_TYPE,
});

export const setDashboardNewUsersDataByDateAction = (data: any): DashboardNewUsersDataByDateActionInterface => ({
  data,
  type: SET_DASHBOARD_NEW_USERS_DATA_BY_DATE_TYPE,
});

export const setDashboardUserSessionsAction = (userSessions: string): DashboardUserSessionsActionInterface => ({
  userSessions,
  type: SET_DASHBOARD_USER_SESSIONS_TYPE,
});

export const setDashboardUserSessionsByDateAction = (data: any): DashboardUserSessionsBYDateActionInterface => ({
  data,
  type: SET_DASHBOARD_USER_SESSIONS_BY_DATE_TYPE,
});

export const setDashboardTotalNewUsersAction = (newUsers: string): DashboardTotalNewUsersActionInterface => ({
  newUsers,
  type: SET_DASHBOARD_TOTAL_NEW_USERS_TYPE,
});

export const setDashboardTotalActiveUsersAction = (activeUsers: string): DashboardTotalActiveUsersActionInterface => ({
  activeUsers,
  type: SET_DASHBOARD_TOTAL_ACTIVE_USERS_TYPE,
});

export const setDashboardEngagementRateAction = (engagementRate: string): DashboardEngagementRateActionInterface => ({
  engagementRate,
  type: SET_DASHBOARD_ENGAGEMENT_RATE_TYPE,
});

export const setDashboardActiveUsersDataByDeviceAction = (
  data: any
): DashboardActiveUsersDataByDeviceActionInterface => ({
  data,
  type: SET_DASHBOARD_ACTIVE_USERS_DATA_BY_DEVICE_TYPE,
});

export const setDashboardCompetitotListAction = (data: any): DashboardCompetitotListActionInterface => ({
  data,
  type: SET_DASHBOARD_COMPETITOR_LIST_TYPE,
});

/** Create type for token reducer actions */
export type tokenActionTypes = AnyAction;

/** Create an immutable form state */
export type ImmutableSocialAnalyticsState = SeamlessImmutable.ImmutableObject<InstagramAnalyticsState>;

/** initial form state */
export const initialState: ImmutableSocialAnalyticsState = SeamlessImmutable({
  dashboard: {
    newUsersByCountry: [],
    newUsersByDate: [],
    activeUsersByDevice: [],
    totalNewUsers: '-',
    userSessions: '-',
    userSessionsByDate: [],
    totalActiveUsers: '-',
    engagementData: [],
    engagementRate: '-',
    competitorList: [],
  },
  ga4DataStatus: false,
});

// Reducers

/** the form reducer function */
export default function inSiteAdminReducer(
  state: ImmutableSocialAnalyticsState = initialState,
  action: tokenActionTypes
): ImmutableSocialAnalyticsState {
  switch (action.type) {
    case RESET_DASHBOARD_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: {
          newUsersByCountry: [],
          newUsersByDate: [],
          activeUsersByDevice: [],
          totalNewUsers: '-',
          userSessions: '-',
          userSessionsByDate: [],
          totalActiveUsers: '-',
          engagementData: [],
          engagementRate: '-',
          competitorList: [],
        } as any,
      });
    case SET_DASHBOARD_NEW_USERS_DATA_BY_COUNTRY_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              newUsersByCountry: (action as any).data,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_ENGAGEMENT_DATA_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              engagementData: (action as any).data,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_USER_SESSIONS_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              userSessions: (action as any).userSessions,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_USER_SESSIONS_BY_DATE_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              userSessionsByDate: (action as any).data,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_NEW_USERS_DATA_BY_DATE_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              newUsersByDate: (action as any).data,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_ENGAGEMENT_RATE_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              engagementRate: (action as any).engagementRate,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_ACTIVE_USERS_DATA_BY_DEVICE_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              activeUsersByDevice: (action as any).data,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_TOTAL_ACTIVE_USERS_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              totalActiveUsers: (action as any).activeUsers,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_TOTAL_NEW_USERS_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              totalNewUsers: (action as any).newUsers,
            }
          : state.dashboard,
      });
    case SET_DASHBOARD_COMPETITOR_LIST_TYPE:
      return SeamlessImmutable({
        ...state,
        dashboard: state.dashboard
          ? {
              ...state.dashboard,
              competitorList: (action as any).data,
            }
          : state.dashboard,
      });
    case SET_GA4DATA_TYPE:
      return SeamlessImmutable({
        ...state,
        ga4DataStatus: (action as any).status,
      });

    default:
      return state;
  }
}

//  selectors

export function getNewUsersByCountry(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.newUsersByCountry.asMutable({ deep: true });
}
export function getEngagementData(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.engagementData.asMutable({ deep: true });
}

export function getNewUsersByDate(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.newUsersByDate.asMutable({ deep: true });
}

export function getActiveUsersByDevice(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.activeUsersByDevice.asMutable({ deep: true });
}
export function getTotalActiveUser(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.totalActiveUsers;
}
export function getTotalNewUser(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.totalNewUsers;
}

export function getUserSessions(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.userSessions;
}

export function getUserSessionsByDate(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.userSessionsByDate.asMutable({ deep: true });
}

export function getEngagementRate(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.engagementRate;
}

export function checkIfGA4DataIsComing(state: Partial<Store>) {
  const totalGa4Data = (state as any).dashboardInfo?.dashboard;
  let stx = true;
  if (totalGa4Data) {
    for (const key in totalGa4Data) {
      if (Array.isArray(totalGa4Data[key]) && totalGa4Data[key].length > 0) {
        stx = true; // Data found
      } else if (typeof totalGa4Data[key] === 'string' && totalGa4Data[key] !== '-') {
        stx = true; // Data found
      }
    }
    stx = false; // No data found
  } else {
    stx = false;
  }
  return stx;
}

export function getCompetitorList(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.dashboard?.competitorList;
}

export function getGA4DataStatus(state: Partial<Store>): any {
  return (state as any).dashboardInfo?.ga4DataStatus;
}
