import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import services from '../../services';
import * as _ from 'lodash';

interface FacebookInfo {
  totalFollowers: string;
  totalNewFollowers: string;
  totalEngagements: string;
  totalPageLikes: string;
  unreadMessageCount: string;
  newFollowersdataByDate: any;
  engagedUsersDataByDate: any;
}

export interface SocialAnalyticsState {
  facebook: FacebookInfo | null;
}

export const SET_TOTAL_FB_FOLLOWERS_TYPE = 'SET_TOTAL_FB_FOLLOWERS_TYPE';
export const SET_TOTAL_FB_NEW_FOLLOWERS_TYPE = 'SET_TOTAL_FB_NEW_FOLLOWERS_TYPE';
export const SET_TOTAL_FB_LIKES_TYPE = 'SET_TOTAL_FB_LIKES_TYPE';
export const SET_TOTAL_FB_ENGAGED_USERS_TYPE = 'SET_TOTAL_FB_ENGAGED_USERS_TYPE';
export const SET_FB_NEW_FOLLOWERS_DATA_BY_DATE_TYPE = 'SET_FB_NEW_FOLLOWERS_DATA_BY_DATE_TYPE';
export const SET_ENGAGED_USERS_DATA_BY_DATE_TYPE = 'SET_ENGAGED_USERS_DATA_BY_DATE_TYPE';
export const RESET_SOCIAL_STATE = 'RESET_SOCIAL_STATE';

// Actions
export interface FacebookTotalFollowersActionInterface extends AnyAction {
  followers: string;
  type: typeof SET_TOTAL_FB_FOLLOWERS_TYPE;
}
export interface FacebookTotalNewFollowersActionInterface extends AnyAction {
  newFollowers: string;
  type: typeof SET_TOTAL_FB_NEW_FOLLOWERS_TYPE;
}

export interface FacebookTotalLikesActionInterface extends AnyAction {
  likes: string;
  type: typeof SET_TOTAL_FB_LIKES_TYPE;
}

export interface FacebookNewFollowesDataByDateActionInterface extends AnyAction {
  data: any;
  type: typeof SET_FB_NEW_FOLLOWERS_DATA_BY_DATE_TYPE;
}
export interface FacebookEngagedUsersDataByDateActionInterface extends AnyAction {
  data: any;
  type: typeof SET_ENGAGED_USERS_DATA_BY_DATE_TYPE;
}
export interface FacebookEngagedUsersActionInterface extends AnyAction {
  engagedUsers: string;
  type: typeof SET_TOTAL_FB_ENGAGED_USERS_TYPE;
}

// Actions

export const setFacebookTotalFollowersAction = (followers: string): FacebookTotalFollowersActionInterface => ({
  followers,
  type: SET_TOTAL_FB_FOLLOWERS_TYPE,
});

export const setFacebookTotalNewFollowersAction = (newFollowers: string): FacebookTotalNewFollowersActionInterface => ({
  newFollowers,
  type: SET_TOTAL_FB_NEW_FOLLOWERS_TYPE,
});

export const setFacebookTotalLikesAction = (likes: string): FacebookTotalLikesActionInterface => ({
  likes,
  type: SET_TOTAL_FB_LIKES_TYPE,
});

export const setFacebookNewFollowesDataByDateAction = (data: any): FacebookNewFollowesDataByDateActionInterface => ({
  data,
  type: SET_FB_NEW_FOLLOWERS_DATA_BY_DATE_TYPE,
});

export const setFacebookEngagedUsersAction = (engagedUsers: string): FacebookEngagedUsersActionInterface => ({
  engagedUsers,
  type: SET_TOTAL_FB_ENGAGED_USERS_TYPE,
});

export const setFacebookEngagedUsersDataByDateAction = (data: any): FacebookEngagedUsersDataByDateActionInterface => ({
  data,
  type: SET_ENGAGED_USERS_DATA_BY_DATE_TYPE,
});

export const resetSocialAction = (): any => ({
  type: RESET_SOCIAL_STATE,
});

/** Create type for token reducer actions */
export type tokenActionTypes = FacebookTotalFollowersActionInterface | AnyAction;

/** Create an immutable form state */
export type ImmutableSocialAnalyticsState = SeamlessImmutable.ImmutableObject<SocialAnalyticsState>;

/** initial form state */
export const initialState: ImmutableSocialAnalyticsState = SeamlessImmutable({
  facebook: {
    totalFollowers: '-',
    totalNewFollowers: '-',
    totalEngagements: '-',
    totalPageLikes: '-',
    unreadMessageCount: '-',
    newFollowersdataByDate: [],
    engagedUsersDataByDate: [],
  },
});

// Reducers

/** the form reducer function */
export default function inSiteAdminReducer(
  state: ImmutableSocialAnalyticsState = initialState,
  action: tokenActionTypes
): ImmutableSocialAnalyticsState {
  switch (action.type) {
    case SET_TOTAL_FB_FOLLOWERS_TYPE:
      return SeamlessImmutable({
        ...state,
        facebook: state.facebook
          ? {
              ...state.facebook,
              totalFollowers: (action as any).followers,
            }
          : state.facebook,
      });
    case SET_TOTAL_FB_NEW_FOLLOWERS_TYPE:
      return SeamlessImmutable({
        ...state,
        facebook: state.facebook
          ? {
              ...state.facebook,
              totalNewFollowers: (action as any).newFollowers,
            }
          : state.facebook,
      });
    case SET_TOTAL_FB_LIKES_TYPE:
      return SeamlessImmutable({
        ...state,
        facebook: state.facebook
          ? {
              ...state.facebook,
              totalPageLikes: (action as any).likes,
            }
          : state.facebook,
      });
    case SET_TOTAL_FB_ENGAGED_USERS_TYPE:
      return SeamlessImmutable({
        ...state,
        facebook: state.facebook
          ? {
              ...state.facebook,
              totalEngagements: (action as any).engagedUsers,
            }
          : state.facebook,
      });
    case SET_FB_NEW_FOLLOWERS_DATA_BY_DATE_TYPE:
      return SeamlessImmutable({
        ...state,
        facebook: state.facebook
          ? {
              ...state.facebook,
              newFollowersdataByDate: (action as any).data,
            }
          : state.facebook,
      });
    case SET_ENGAGED_USERS_DATA_BY_DATE_TYPE:
      return SeamlessImmutable({
        ...state,
        facebook: state.facebook
          ? {
              ...state.facebook,
              engagedUsersDataByDate: (action as any).data,
            }
          : state.facebook,
      });
    case RESET_SOCIAL_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

// async thunk
// export const fetchCustomers = (query: string = ''): any => {
//   return async (dispatch: any, getState: any) => {
//     let response;
//     dispatch(
//       setCutomersAction({
//         loading: true,
//         loaded: false,
//         items: [],
//         receivedAt: 0,
//       })
//     );
//     try {
//       response = await services.getAllCustomer(query);
//       const data = {
//         loading: false,
//         loaded: true,
//         items: response,
//         receivedAt: 0,
//       };
//       dispatch(setCutomersAction(data));
//       return Promise.resolve(response);
//     } catch (error) {
//       dispatch(
//         setCutomersAction({
//           loading: false,
//           loaded: true,
//           items: [],
//           receivedAt: 0,
//         })
//       );
//       return Promise.reject(error);
//     }
//   };
// };

// selectors
export function getFacebookTotalFollowers(state: Partial<Store>): string {
  return (state as any).socialAnalyticsInfo?.facebook?.totalFollowers;
}

export function getFacebookTotalNewFollowers(state: Partial<Store>): string {
  return (state as any).socialAnalyticsInfo?.facebook?.totalNewFollowers;
}

export function getFacebookTotalLikes(state: Partial<Store>): string {
  return (state as any).socialAnalyticsInfo?.facebook?.totalPageLikes;
}

export function getFacebookEngagedUsers(state: Partial<Store>): string {
  return (state as any).socialAnalyticsInfo?.facebook?.totalEngagements;
}

export function getFacebookNewFollowersDataByDate(state: Partial<Store>): any {
  return (state as any).socialAnalyticsInfo?.facebook?.newFollowersdataByDate.asMutable({ deep: true });
}

export function getFacebookEngagedUsersDataByDate(state: Partial<Store>): any {
  return (state as any).socialAnalyticsInfo?.facebook?.engagedUsersDataByDate.asMutable({ deep: true });
}
