import { memo } from 'react';
import { useSelector } from 'react-redux';

import { getAccountType, getSelectedAccount, getUserRole } from '../../../../store/ducks/inSiteAppState';
import { MenuItem as IMenuItem } from '../../model/type/menu-item';
import { menuList, adminMenuList, mobileMenuList, orgAdminMenuList } from '../../model/config/menu-list';
import { MenuItem } from '../menu-item/menu-item';
import { SubmenuItem } from '../sub-menu-item/sub-menu-item';

import cls from './nav-bar.module.scss';
import { isMobile } from '../../../../utils/is-mobile';
import { ReactComponent as HamburgerIcon } from '../../../../assets/icons/icn-hamburger.svg';

interface Props {
  setMenuOpen?: (isOpen: boolean) => void;
}

const NavBar = memo((props: Props) => {
  const { setMenuOpen } = props;
  const acType: string = useSelector(getAccountType);
  const selectedAccount: any = useSelector(getSelectedAccount);
  let pathName = window.location.pathname.split('/');
  const role = useSelector(getUserRole);

  let currentMenuList = menuList;
  if (role === 'customer' && isMobile()) {
    currentMenuList = mobileMenuList;
  }
  if (role === 'admin') {
    currentMenuList = adminMenuList;
  }

  let currentItem =
    currentMenuList.find((item) => item.path === '/' + pathName[1]) ||
    (role === 'customer' ? menuList[1] : menuList[0]);
  const currentSubItem = currentItem.subMenuItems?.find((item) => item.path === '/' + pathName[1] + '/' + pathName[2]);

  if (acType === 'admin') {
    currentMenuList = orgAdminMenuList;

    currentItem =
      currentMenuList.find((item) => item.path === '/' + pathName[1] + '/' + pathName[2]) || currentMenuList[0];
  }

  if (acType === 'default' && selectedAccount && selectedAccount?.resources) {
    currentMenuList = currentMenuList.filter((item: any) => selectedAccount?.resources.includes(item.id));
    currentItem = currentMenuList.find((item) => item.path === '/' + pathName[1]) || currentMenuList[0];
  }

  console.log(currentMenuList);

  const handleMenuClose = () => {
    if (setMenuOpen) {
      setMenuOpen(false);
    }
  };

  return (
    <>
      {isMobile() && (
        <button className={cls.hamburger} onClick={handleMenuClose}>
          <HamburgerIcon />
        </button>
      )}
      <div className={cls.list}>
        {currentMenuList.map((item: IMenuItem) => {
          return (
            <MenuItem key={item.path} item={item} currentItem={currentItem}>
              {item.subMenuItems?.map((subItem: IMenuItem) => {
                return <SubmenuItem key={subItem.path} item={subItem} currentItem={currentSubItem} />;
              })}
            </MenuItem>
          );
        })}
      </div>
    </>
  );
});

export { NavBar };
