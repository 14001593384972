import React, { createContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import services from '../services';
import {
  getIsUserAuthenticated,
  setIsUserAuthenticatedAction,
  setUserRoleAction,
  setUserInfoAction,
  setAccountTypeAction,
} from '../store/ducks/inSiteAppState';
import { decryptRoleSecrets } from '../utils';

export interface AuthContextType {
  isAuthenticated: boolean;
  isFetching: boolean;
  setIsAuthenticated?(): void;
  setIsFetching?(): void;
  signIn(username: string, password: string): void;
  signOut(): void;
  register(email: string, firstName: string, lastName: string): void;
  error: any;
  setContextError: any;
}

export const AuthContext = createContext<AuthContextType>(null!);

export default function AuthProvider(props: React.PropsWithChildren<{}>) {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setContextError] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getIsUserAuthenticated);

  const logout = async () => {
    try {
      dispatch(setIsUserAuthenticatedAction(false));
      dispatch(setUserInfoAction({}));
      dispatch(setAccountTypeAction(''));
      await services.logout();
      return Promise.resolve();
    } catch (error: any) {
      console.log(error);
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      setIsFetching(true);
      let response = await services.login(username, password);
      const enc = response.headers.enc;
      const iv = response.headers.iv;
      if (response.status === 204 && enc && iv) {
        dispatch(setUserRoleAction(decryptRoleSecrets(enc, iv)));
        dispatch(setAccountTypeAction('default'));
        dispatch(setIsUserAuthenticatedAction(true));
        setIsFetching(false);
        navigate('/dashboard');
        return Promise.resolve();
      } else {
        dispatch(setIsUserAuthenticatedAction(false));
        setIsFetching(false);
        return Promise.reject();
      }
    } catch (error: any) {
      let errorData = error?.response?.data;
      if (errorData?.detail === 'Incorect password' || errorData?.detail === 'Incorect email')
        errorData.detail = 'Incorrect email or password';
      setIsFetching(false);
      setContextError(errorData);
      return Promise.reject();
    }
  };

  const signOut = () => {
    logout();
  };

  const register = async (email: string, firstName: string, lastName: string) => {
    try {
      setIsFetching(true);
      let data = {
        email: email,
        name: {
          first_name: firstName,
          last_name: lastName,
        },
      };
      await services.register(data);
      setIsFetching(false);
    } catch (error: any) {
      setIsFetching(false);
      let errorData = error?.response?.data;
      setContextError(errorData);
      return Promise.reject();
    }
  };

  let contextValue = {
    isAuthenticated,
    isFetching,
    signIn,
    signOut,
    register,
    error,
    setContextError,
  } as AuthContextType;

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}
