// ClosePage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../components/content-wrapper';
import { getGMBAccountList, getGMBAccountLocations, submitDefaultAccount } from '../../services/gmb-service';
import {
  getUserInfo,
  getUserSocialConnections,
  updateRankworkConnectionAction,
} from '../../store/ducks/inSiteAppState';
import cls from './g-list.module.scss';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { Box, ListSubheader, TextField, Typography } from '@mui/material';
import { getUserOrg } from '../../utils';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from '../../components/button';

const GMBAccountSelectionPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentAccount, setCurrentAccount] = useState<any | null>(null);
  const [currentLocation, setCurrentLocation] = useState<any | null>(null);
  const [step, setStep] = useState<number>(1);
  const [searchText, setSearchText] = useState('');
  const [accounts, setAccounts] = useState<any | null>(null);
  const [locations, setLocations] = useState<any | null>(null);
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);
  const orgId: string = userOrg?.access_to || '';
  const location = useLocation();

  const getAllAccs = async () => {
    try {
      setIsLoading(true);

      const data = {
        org_id: 'org_' + orgId,
      };
      const response = await getGMBAccountList(data);
      setAccounts(response);
      if (response && response.length > 0) setCurrentAccount(response[0]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (orgId) getAllAccs();
    return () => {
      setCurrentAccount(null);
    };
  }, [orgId]);

  const handleItemSelection = async () => {
    try {
      setIsProcessing(true);
      const data = {
        connection_id: currentAccount?.connection_id,
        gmb_account_id: currentAccount?.account_id,
        org_id: 'org_' + orgId,
      };
      const response = await getGMBAccountLocations(data);
      const locationList = response;
      if (!locationList) return Promise.reject('No GMB location response');
      if (!Array.isArray(locationList)) return Promise.reject('GMB location response array is empty');
      if (locationList.length === 1) {
        const singleLocation = locationList[0];
        const data = {
          connection_id: currentAccount?.connection_id || '',
          default_customer_account: {
            account_id: currentAccount?.account_id || '',
            account_name: currentAccount?.account_name || '',
            location_id: singleLocation?.name.split('/')[1] || '',
            location_title: singleLocation?.title || '',
          },
          org_id: 'org_' + orgId,
        };
        await submitDefaultAccount(data);
        setIsProcessing(false);
        navigate('/integration');
      } else {
        setLocations(locationList);
        setStep(2);
        setIsProcessing(false);
      }
    } catch (err) {
      setIsProcessing(false);
      console.log(err);
    }
  };

  const submitSelection = async () => {
    try {
      if (!currentLocation) return;
      setIsLoading(true);
      const data = {
        connection_id: currentAccount?.connection_id || '',
        default_customer_account: {
          account_id: currentAccount?.account_id || '',
          account_name: currentAccount?.account_name || '',
          location_id: currentLocation?.name.split('/')[1] || '',
          location_title: currentLocation?.title || '',
        },
        org_id: 'org_' + orgId,
      };

      await submitDefaultAccount(data);
      navigate('/integration');
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  console.log(currentAccount, step);

  return (
    <ContentWrapper>
      {!isLoading && (!accounts || accounts.length === 0) && (
        <div className={cls.gmbAccountListWrapperSmall}>
          You don't have any Google Business account. Go Back{' '}
          <span>
            <a onClick={() => navigate('/integration')} style={{ marginLeft: '10px', cursor: 'pointer' }}>
              Click
            </a>
          </span>{' '}
        </div>
      )}
      {isLoading && <div>Collecting your accounts...</div>}
      {!isLoading && accounts && accounts.length !== 0 && (
        <div className={cls.gmbAccountListWrapper}>
          <Typography className={cls.gmbHeading}>Google Business Accounts</Typography>
          {step === 1 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '480px',
                  overflowY: 'auto',
                  height: '100%',
                }}
              >
                <RadioGroup
                  aria-label="role type"
                  defaultValue={accounts[0].account_id}
                  name="role-radio-buttons-group"
                  style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                >
                  {accounts.map((item: any) => (
                    <FormControlLabel
                      value={item.account_id}
                      onClick={() => {
                        setCurrentAccount(item);
                      }}
                      control={<Radio />}
                      label={
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{item.account_name}</span>
                          <span style={{ fontSize: '12px', color: '#888888' }}>{item.account_id}</span>
                        </div>
                      }
                      className={cls.singleDiv}
                    />
                  ))}
                </RadioGroup>
              </div>
              <div style={{ display: 'flex', width: '100%', margin: '10px', alignItems: 'center' }}>
                <Button
                  primary
                  style={{ height: '30px', fontSize: '10px', marginTop: '10px' }}
                  onClick={() => {
                    handleItemSelection();
                  }}
                  disabled={isProcessing}
                >
                  Select
                </Button>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '480px',
                  overflowY: 'auto',
                  height: '100%',
                }}
              >
                <RadioGroup
                  aria-label="role type"
                  defaultValue="employee"
                  name="role-radio-buttons-group"
                  style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                >
                  {locations &&
                    locations.map((item: any) => (
                      <FormControlLabel
                        value={item.name}
                        onClick={() => {
                          setCurrentLocation(item);
                        }}
                        control={<Radio />}
                        label={
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{item.title}</span>
                            <span style={{ fontSize: '12px', color: '#888888' }}>{item.name}</span>
                          </div>
                        }
                        className={cls.singleDiv}
                      />
                    ))}
                </RadioGroup>
              </div>

              <div style={{ display: 'flex', width: '100%', gap: '20px', margin: '10px' }}>
                <Button
                  transparent
                  style={{ height: '30px', fontSize: '10px', marginTop: '10px' }}
                  onClick={() => {
                    setCurrentLocation(null);
                    setStep(1);
                  }}
                >
                  Back
                </Button>
                <Button
                  primary
                  style={{ height: '30px', fontSize: '10px', marginTop: '10px' }}
                  onClick={() => {
                    submitSelection();
                  }}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </ContentWrapper>
  );
};

export default GMBAccountSelectionPage;
