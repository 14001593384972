import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext/useAuthContext';
import services from '../../services';
import { fetchProducts, setSelectedAccountAction, setUserInfoAction } from '../../store/ducks/inSiteAppState';
import { getExternalOrgAccounts, getUserOrg } from '../../utils';

function PrivateRoute({ children }: any) {
  const { isAuthenticated } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getAccountInfo = async () => {
    try {
      console.log('Account Info Call');
      let response = await services.getAccountInfo();
      // let response = {
      //   _id: '66e9a4dd2efbb268389c4f76',
      //   working_account: 'acc_67044786b3366ac68d13750c',
      //   email: 'in-site@rankworks.com',
      //   name: {
      //     first_name: '',
      //     last_name: '',
      //   },
      //   phone: null,
      //   address: null,
      //   term_url: '',
      //   permissions: [
      //     {
      //       _id: '67044786b3366ac68d13750b',
      //       access_to: '67044786b3366ac68d13750a',
      //       resources: [
      //         'VIEW_CUSTOMER_ACCOUNT_USER',
      //         'EDIT_CUSTOMER_ACCOUNT_USER',
      //         'VIEW_CUSTOMER_ACCOUNT',
      //         'EDIT_CUSTOMER_ACCOUNT',
      //         'VIEW_BILLING',
      //         'EDIT_BILLING',
      //         'INTEGRATION',
      //         'VIEW_MANAGER_ACCOUNT',
      //         'EDIT_MANAGER_ACCOUNT',
      //         'VIEW_MANAGER_ACCOUNT_USER',
      //         'EDIT_MANAGER_ACCOUNT_USER',
      //       ],
      //       access_expires: 'Never',
      //       status: 'ACTIVATED',
      //       display_name: '',
      //       last_signed_in: '',
      //       event_logs: null,
      //       invited_by: 'in-site@rankworks.com',
      //       date_invited: '1728333702.887844',
      //       organization_details: {
      //         _id: '67044786b3366ac68d13750a',
      //         name: '',
      //         plans: [
      //           {
      //             plan_type: 'plans',
      //             plan_name: 'Pro',
      //             plan_id: 'prod_PzqQH62KIexzP4',
      //             plan_credits: {
      //               ai: '10',
      //               category: 'plans',
      //               dashboard: 'free',
      //               gbpupload: '10',
      //               rec: '5',
      //               seodashboard: 'free',
      //               socialupload: '10',
      //               stock: '10',
      //             },
      //             created_at: '2024-10-07 20:42:55 UTC',
      //           },
      //         ],
      //         primary_acc_id: '67044786b3366ac68d13750c',
      //         accounts: [
      //           {
      //             _id: '67044786b3366ac68d13750c',
      //             is_ecommerce: false,
      //             name: 'HHHHHH',
      //             display_name: null,
      //             primary_category: 'abc',
      //             org_id: '67044786b3366ac68d13750a',
      //             websites: [
      //               [
      //                 {
      //                   website_id: '6704478bb3366ac68d13750d',
      //                   website: 'https://dev.business.rankworks.com',
      //                   gtm_public_id: 'GTM-PC5F7WML',
      //                   ga4_property_id: '462024415',
      //                 },
      //               ],
      //             ],
      //             locations: [
      //               {
      //                 location_id: '6704478bb3366ac68d13750e',
      //                 display_name: '',
      //               },
      //             ],
      //           },
      //         ],
      //       },
      //     },
      //     {
      //       _id: '66feaee6a004e2d4075c1d93',
      //       access_to: '66fdad04af01623e9ce882ef',
      //       resources: ['ADS', 'GMB'],
      //       access_expires: 'Never',
      //       status: 'ACTIVATED',
      //       display_name: '',
      //       last_signed_in: '',
      //       event_logs: null,
      //       invited_by: 'alipu@rankworks.com',
      //       date_invited: '1727966950.541569',
      //       account_details: {
      //         _id: '66fdad04af01623e9ce882ef',
      //         is_ecommerce: true,
      //         name: 'test',
      //         display_name: 'abc',
      //         primary_category: 'Abbey',
      //         org_id: '66fdad04af01623e9ce882ed',
      //         websites: [
      //           {
      //             website_id: '66fdad09af01623e9ce882f0',
      //             website: 'http://hh.ca',
      //             gtm_public_id: 'GTM-WXV6MZHD',
      //             ga4_property_id: '461319451',
      //             updated_at: '2024-10-03 15:39:12 UTC',
      //           },
      //         ],
      //         locations: [
      //           {
      //             location_id: '66fdad09af01623e9ce882f1',
      //             display_name: '',
      //           },
      //         ],
      //         connections: {
      //           google_ads: {
      //             connection_id: '4a8fd010-acf2-47b7-b140-35f9931db3ee',
      //             default_customer_account: {
      //               customer_account_id: '5422792764',
      //               descriptive_name: 'J&M Fishing Guide',
      //               currency_code: 'CAD',
      //               time_zone: 'America/New_York',
      //               status: {
      //                 index: 3,
      //                 name: 'CANCELED',
      //                 description:
      //                   'Indicates a canceled account unable to serve ads. Can be reactivated by an admin user.',
      //               },
      //               manager_account_id: '6305345192',
      //             },
      //           },
      //           gmb: {
      //             connection_id: '75bcc2b8-bfd4-4c6c-9a12-00b3ab438617',
      //           },
      //         },
      //       },
      //     },
      //   ],
      //   avatar_url: 'https://file.rankworks.com/default-avatar.png',
      //   notifications: null,
      //   plugin: '',
      // };
      const userOrg: any = getUserOrg(response?.permissions);
      const externalAccounts: any = getExternalOrgAccounts(response?.permissions);
      const selectedAccountId = response?.working_account.split('_')[1] || '';
      let selectedAccountFilter = [];
      if (selectedAccountId && userOrg && externalAccounts) {
        selectedAccountFilter = userOrg?.organization_details?.accounts.filter(
          (item: any) => item._id === selectedAccountId
        );
        if (selectedAccountFilter.length === 0)
          selectedAccountFilter = externalAccounts.filter(
            (itemEx: any) => itemEx?.account_details._id === selectedAccountId
          );
      }
      let selectedAccount =
        selectedAccountFilter.length === 0
          ? userOrg?.organization_details?.accounts[0] || externalAccounts[0]
          : selectedAccountFilter[0];

      console.log(selectedAccount);
      // FIXME: No check for response. If return error try/cath not catch it.
      dispatch(setUserInfoAction(response));
      dispatch(setSelectedAccountAction(selectedAccount));
      const redirectVar = selectedAccount.hasOwnProperty('resources') && selectedAccount?.resources[0];
      if (redirectVar) {
        if (redirectVar === 'SEO') navigate('/seo');
        if (redirectVar === 'SMP') navigate('/social');
        if (redirectVar === 'GMB') navigate('/gmb');
        if (redirectVar === 'GA4') navigate('/dashboard');
        if (redirectVar === 'ADS') navigate('/advertising');
      }
    } catch (error) {
      console.log(error);
      Promise.reject(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      getAccountInfo();
      //dispatch(fetchProducts());
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return isAuthenticated ? children : <Navigate to="/" />;
}

export default PrivateRoute;
