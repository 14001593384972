import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ContentWrapper } from '../../components/content-wrapper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './accounts.scss';
import AccountList from './AccountList/AccountList';
import InSiteSnackBar from '../../components/InSIteSnackBar/InSiteSnackBar';
import AddAccount from './AddAccount/AddAccount';
import { getUserInfo } from '../../store/ducks/inSiteAppState';
import { getUserOrg } from '../../utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OrgAdminAccounts = memo(() => {
  const [value, setValue] = useState(0);
  const [customerEditId, setCustomerEditId] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const userInfo: any = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);
  const customerObj = null;
  const userResources = (userInfo?.permissions && userOrg?.resources) || [];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCustomerEditId('');
  };

  const handleEditCustomer = (id: any) => {
    setCustomerEditId(id);
    setValue(1);
  };

  return (
    <ContentWrapper>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
          <Tabs value={value} className="tabs" onChange={handleChangeTab} aria-label="tabs">
            <Tab className="tab" label="Accounts" {...a11yProps(0)} />
            {(userResources.includes('EDIT_CUSTOMER_ACCOUNT') ||
              userResources.includes('EDIT_MANAGER_ACCOUNT_USER')) && (
              <Tab className="tab" label={customerEditId ? 'Edit Account' : 'Add Account'} {...a11yProps(1)} />
            )}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <InSiteSnackBar alertOpen={alertOpen} alertMessage={alertMessage} setAlertOpen={setAlertOpen} />
          <AccountList
            handleEditCustomer={handleEditCustomer}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InSiteSnackBar alertOpen={alertOpen} alertMessage={alertMessage} setAlertOpen={setAlertOpen} />
          <AddAccount
            customerObj={customerObj}
            cancelAction={handleChangeTab}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
          />
        </TabPanel>
      </Box>
    </ContentWrapper>
  );
});

export { OrgAdminAccounts };
