import { FunctionComponent } from 'react';
import IconButton from '@mui/material/IconButton';
import { Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface InSiteSnackBarProps {
  alertMessage: string;
  alertOpen: boolean;
  setAlertOpen: any;
}

const InSiteSnackBar: FunctionComponent<InSiteSnackBarProps> = ({ alertMessage, alertOpen, setAlertOpen }: any) => {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setAlertOpen(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={alertOpen}
      autoHideDuration={5000}
      onClose={() => {
        setAlertOpen(false);
      }}
      message={alertMessage}
      action={action}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    />
  );
};

export default InSiteSnackBar;
